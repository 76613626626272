<form class="needs-validation" [formGroup]="PlansForm" (ngSubmit)="onReactiveFormSubmit()">
  <div class="form-row">
    <div class="col-md-12 form-group">
      <label for="PlanType">{{'Plan Type' | translate}}</label>
      <select formControlName="type"
              (change)="planTypeChangedTarget($event.target)"
              [ngClass]="{ 'is-invalid': submitted && plansF.type.invalid }"
              class="form-control"
              id="PlanType"
              required >
          <option value="1">Revenue</option>
          <option value="2">CPA</option>
          <option value="3">Referral</option>
          <option value="4">Hybrid</option>
      </select>
      <div *ngIf="submitted && (plansF.type.invalid || plansF.type.errors?.required)" class="invalid-tooltip">
        {{'Required' | translate}}
      </div>
    </div>
    <div class="col-md-12 form-group">
      <label for="Plan">{{'Plan' | translate}}</label>
      <select formControlName="planId"
              (change)="planIdChangedTarget($event.target)"
              [ngClass]="{ 'is-invalid': submitted && plansF.planId.invalid }"
              class="form-control"
              id="Plan"
              placeholder="Plan"
              required >
        <ng-template let-item ngFor [ngForOf]="plansList">
          <option [value]="item.id">{{item.name}}</option>
        </ng-template>
      </select>
      <div *ngIf="submitted && (plansF.planId.invalid || plansF.planId.errors?.required)" class="invalid-tooltip">
        {{'Required' | translate}}
      </div>
    </div>

    <div class="col-md-12 form-group" *ngIf="plan">
      <app-commission-card [dataItem]="plan" *ngIf="PlansForm.value.type != 4"></app-commission-card>
      <div style="background: #FFFFFF;
    border-radius: 10px;
    margin: 10px;" *ngIf="PlansForm.value.type == 4">
        <div class="hybrid-plan-tabs pt-1">
          <div class="active" (click)="plan.isShowRev = false" [ngClass]="{active: !plan.isShowRev}">CPA</div>
          <div (click)="plan.isShowRev = true" [ngClass]="{active: plan.isShowRev}">Revenue</div>
          {{plan.isShowRev}}
        </div>
        <app-commission-card [dataItem]="plan.revenuePlan"  *ngIf="plan.isShowRev"></app-commission-card>
        <app-commission-card [dataItem]="plan.cpaPlan" *ngIf="!plan.isShowRev"></app-commission-card>
      </div>
    </div>
  </div>

</form>
