<a class="nav-link apptogglefullscreen" href="javascript:;"
   (click)="openModal(customContent)"><i class="font-medium-3 ft-user-plus"></i></a>

<ng-template #customContent let-c="close" let-d="dismiss">
  <div *ngIf="activeUserFormType == userFormTypes.hidden">
    <div class="modal-header" >
      <h4 class="modal-title" *ngIf="activeUserFormType == userFormTypes.hidden">{{'Create User' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>

        <ul class="list-unstyled mb-0">
          <li class="d-block mb-2">
            <div class="radio">
              <input type="radio" name="user-type" id="radio1" [(ngModel)]="userType" value="1">
              <label for="radio1">{{'Agent' | translate}}</label>
            </div>
          </li>
          <li class="d-block mb-2">
            <div class="radio">
              <input type="radio" name="user-type" id="radio2"  [(ngModel)]="userType" value="2">
              <label for="radio2">{{'Player' | translate}}</label>
            </div>
          </li>
        </ul>
      </form>

    </div>
    <div class="modal-footer" *ngIf="userTypes.Player == userType">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">{{'Close' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="showUserForm(userFormTypes.showPlayerForm)">{{'Next' | translate}}</button>
    </div>
    <div class="modal-footer" *ngIf="userTypes.Agent == userType">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">Close</button>
      <button type="button" class="btn btn-primary" (click)="showUserForm(userFormTypes.showAgentForm)">{{'Next' | translate}}</button>
    </div>
  </div>



  <div *ngIf="activeUserFormType == userFormTypes.showAgentForm">
    <app-create-agent *ngIf="activeUserFormType == userFormTypes.showAgentForm"  [popup]="c"></app-create-agent>
  </div>

  <div *ngIf="activeUserFormType == userFormTypes.showPlayerForm">
    <app-create-player *ngIf="activeUserFormType == userFormTypes.showPlayerForm" [popup]="c"></app-create-player>
  </div>
</ng-template>
