import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {ColumnBase, DataBindingDirective, GridComponent} from '@progress/kendo-angular-grid';
import {GridPostService} from '../../services/kendo-grid/kendo-grid.service';
import {Subscription} from 'rxjs';
import {RequestsService} from '../../services/requests.service';
import {SettingsService} from '../../services/settings.service';

@Directive({
  providers: [GridPostService],
  // tslint:disable-next-line:directive-selector
  selector: '[gridDataBinding]'
})

export class GridDataBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  public serviceSubscription: Subscription | undefined;
  @Input() Base_url: any;
  @Input() filterWithOperationType = true;
  @Input() httpType: any;
  @Input() tableName: any;
  @Input() formFilters: any = {};
  @Input() hasPaging = true;
 // pageSize: number | undefined;
  public tableColumns: any = {};



  constructor(
    private products: GridPostService,
    public override grid: GridComponent,
    protected settings: SettingsService,
    protected http: RequestsService) {
    super(grid);
  }

  public override ngOnInit(): void {

    this.http.httpGet(this.settings.apiUrls.settings.getGridColumns + this.tableName)
      .subscribe((res: any) => {
        this.grid.pageable = {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 25, 50, 100, 500],
          previousNext: true,
          position: 'bottom'
        }

        if (!this.hasPaging) {
          this.grid.pageable = false;
        }

        this.grid.scrollable = 'scrollable';

        this.grid.columns.forEach((item: any, index: any) => {
          if (item.field) {
            item.orderIndex = index;
            // @ts-ignore
            this.tableColumns[item.field] = {
              hidden: item.hidden === 'true' ? item.hidden : '',
              orderIndex: index,
              width: item.width,
            }
          }

          if (!item.field && !item['showSelectAll']) {
            item.orderIndex = 99999;
          }

        });

        if (res && res.data) {
          const savedData = JSON.parse(res.data.Settings)
          for (const key in this.tableColumns) {

            if (savedData[key]) {
              /*console.log(savedData[key].field);
              console.log(savedData[key].orderIndex);
              console.log(savedData[key].hidden);*/

              this.tableColumns[key].hidden = savedData[key].hidden === 'true' ? savedData[key].hidden : '';
              this.tableColumns[key].orderIndex = savedData[key].orderIndex;
              this.tableColumns[key].width = savedData[key].width;
              this.grid.columns.filter((gridCol: ColumnBase) => {
                // @ts-ignore
                if (gridCol['field'] === key) {
                  gridCol.hidden = savedData[key].hidden;
                  gridCol.orderIndex = savedData[key].orderIndex;
                  gridCol.width = savedData[key].width;
                }
                // @ts-ignore
                if (!gridCol['field'] && !gridCol['showSelectAll']) {
                  gridCol.orderIndex = 9999;
                }

                return true;
              })
            }
          }
        }

        this.serviceSubscription = this.products.subscribe((result) => {
          this.grid.loading = false;
          this.grid.data = result;
          this.notifyDataChange();
        });
      })


    this.products.BASE_URL = this.Base_url;
    this.products.filterWithOperationType = this.filterWithOperationType;
    this.products.httpType = this.httpType;
    /**/

    super.ngOnInit();
    this.rebind();


    this.grid.columnReorder.subscribe((res: any) => {
      if (res) {
        this.saveChange();
      }
    });
    this.grid.columnResize.subscribe((res: any) => {
      this.saveChange();
    });
    this.grid.columnVisibilityChange.subscribe((res: any) => {
      this.saveChange();
    });

  }

  public override ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  public override rebind(filter?: any): void {
    this.products.BASE_URL = this.Base_url;
    this.grid.loading = true;
    if (filter) {
      this.formFilters = filter;
    }

    this.products.query(this.state, this.formFilters);
  }

  public setData(newData: any) {
    const a: any = this.grid.data;
    a.data.push(newData)
  }

  getGridColumnInfo() {
    this.grid.columns.forEach((item: any, index: any) => {
      this.tableColumns[item.field] = {
        hidden: item.hidden,
        orderIndex: item.field ? item.orderIndex : 9999,
        width: item.width,
      }
    });
  }

  saveChange() {
    setTimeout(() => {
      this.getGridColumnInfo();
      this.http.httpPost(this.settings.apiUrls.settings.updateGrid, {
        Name: this.tableName,
        // Settings: '{}'
        Settings: JSON.stringify(this.tableColumns)
      }).subscribe()
    }, 500)

  }


}
