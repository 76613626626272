import {EventEmitter, Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import swal from 'sweetalert2';
import {SettingsService} from './settings.service';
import {AgentPermissions} from '../enums/enums.enum';
import {environment} from '../../../environments/environment';
declare var $: any;
@Injectable({
  providedIn: 'root'
})

export class CoreService {
  public PermissionsList = AgentPermissions;
  public openTransferEvent = new EventEmitter();

  constructor(
    private Settings: SettingsService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) {
    this.openSignalR(localStorage.getItem('uid'), localStorage.getItem('session'));
    this.Settings.sigCon.hubProxy.on('walletInfo', (ev: any) => {
      if (ev) {
        this.Settings.userData.wallet = ev;
        localStorage.setItem('user', JSON.stringify(this.Settings.userData));
      }
    })
  }

  copyObj(obj: any) {
    return JSON.parse(JSON.stringify(obj))
  }

  showSpinner() {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  }

  hideSpinner() {
    this.spinner.hide();
  }

  RemoveToastr() {
    this.toastr.clear();
  }

  showInfoMsg(description: any) {
    swal.fire({
      position: 'top-end',
      icon: 'success',
      title: description,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
  }

  showSuccessMsg(description: any, title: any) {
    this.RemoveToastr();
    this.toastr.success(description, title, {
      positionClass: 'toast-top-right'
    });
  }

  openSignalR(uId: any, token: any) {
    this.Settings.sigCon.init(`${this.Settings.SignalRUrl}signalr`, 'ChatHub', this.Settings.partnerId, uId, token, this.Settings.activeLangId);
    this.Settings.sigCon.start(() => {
      this.Settings.SignalrHasConnected = true;
    });
    this.Settings.sigCon.starting$.subscribe(() => {

    });
  }


  // checkPerm

  checkPermissions(permisions: any): boolean {
    if (!this.Settings.userData || !this.Settings.userData.accesses || !this.Settings.userData.accesses.length) {
      return false;
    }

    let state = false;
    if (permisions === 'isAdmin') {
      if (this.Settings.userData.isAdmin) {
        return true
      } else {
        return false
      }
    }
    for (const perm in permisions) {
      if (this.Settings.userData.accesses.find((item: any): any => {
        if (item.key === permisions[perm] && item.ownState) {
          return true;
        }
      })) {
        return state = true;
      }
    }

    return state;
  }

  checkPermissionsOr(permisions: any) {
    if (!this.Settings.userData || !this.Settings.userData.accesses || !this.Settings.userData.accesses.length) {
      return;
    }

    const permisionsLength = permisions.length;
    let checkedLength = 0;

    for (const perm in permisions) {
      this.Settings.userData.accesses.find((item: any): any => {
        if (item.key === permisions[perm] && item.ownState) {
          return checkedLength += 1;
        }
      })
    }

    return checkedLength > 0 ? true : false;
  }


  public ExportExcel(url: any, body: any, name?: any) {
    $.ajax({
      url: url,
      type: 'Post',
      data: JSON.stringify(body),
      contentType: 'application/json; charset=utf-8',
      /*dataType:"json",*/
      headers: {
        'pid': environment.partnerId,
        'uLang': this.Settings.activeLangId,
        'languageId': this.Settings.activeLangId,
        session: localStorage.getItem('session') || '',
        uid: localStorage.getItem('uid') || ''
      },
      success: function (data: any, status: any, xhr: any) {
        const contentType = 'application/vnd.ms-excel';

        let filename = name + '.xls';
        const  disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const  filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const  matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        try {
          const  blob = new Blob([data], {type: contentType});

          const  downloadUrl = URL.createObjectURL(blob);
          const  a = document.createElement('a');
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();

        } catch (exc) {
          console.log('Save Blob method failed with the following exception.');
          console.log(exc);
        }
      }
    });
  }


  copyTextToClipboard(text: any) {
    const textArea: any = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    textArea.style.width = '2em';
    textArea.style.height = '2em';

    textArea.style.padding = 0;

    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }
}
