import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../services/settings.service';
import {CoreService} from '../../../services/core.service';
import {RequestsService} from '../../../services/requests.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-register-step',
  templateUrl: './register-step.component.html'
})

export class RegisterStepComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  public AgentForm: any = new FormGroup({
    FirstName: new FormControl(''),
    SecondName: new FormControl(''),
    LastName: new FormControl(''),
    SecondLastName: new FormControl(''),
    Username: new FormControl('', [Validators.required]),
    Mobile: new FormControl(''),
    Email: new FormControl('', [Validators.required]),
    CountryId: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
  });


  public currenciesList: Array<any> = [];
  public countriesList: Array<any> = [];
  public submitted = false;

  constructor(
    public Settings: SettingsService,
    public auth: AuthService,
    public Core: CoreService,
    public Http: RequestsService
  ) {


    this.Http.httpGet(this.Settings.apiUrls.options.currencies)
      .subscribe((res: any) => {
        if (res.data) {
          this.currenciesList = res.data;
        }
      });
    this.Http.httpGet(`${this.Settings.apiUrls.options.countries}?languageId=en`)
      .subscribe((res: any) => {
        if (res.data) {
          this.countriesList = res.data;
        }
      });
  }

  ngOnInit() {
    this.AgentForm.patchValue({
      FirstName: this.Settings.userData.name,
      SecondName: '',
      LastName: this.Settings.userData.surname,
      SecondLastName: '',
      Username: this.Settings.userData.username,
      Mobile: '',
      Email: this.Settings.userData.email,
      CountryId: '',
      Currency: this.Settings.userData.currency,
    });
  }

  get f() {
    return this.AgentForm.controls;
  }


  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.AgentForm.invalid) {
      return;
    }
  }

  changeStep(stepId: any) {
    this.submitted = true;
    if (this.AgentForm.invalid) {
      return;
    }
  }


  ngOnDestroy() {
  }


  createAgent() {
    const agentObj = {
      'name': this.AgentForm.value.FirstName,
      'secname': this.AgentForm.value.SecondName,
      'surname': this.AgentForm.value.LastName,
      'username': this.AgentForm.value.Username,
      'email': this.AgentForm.value.Email,
      'mob': this.AgentForm.value.Mobile,
      'country': this.AgentForm.value.CountryId,
      'currency': this.AgentForm.value.Currency,
    };

    this.Http.httpPost(this.Settings.apiUrls.agents.agent + this.Settings.userId + '/update', agentObj, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.popup('Cross click');
          this.Core.showInfoMsg('Agent Created');
        }
      })
  }

  logout() {
    this.auth.logout()
  }
}
