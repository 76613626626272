
<div class="modal-header" >
  <h4 class="modal-title" *ngIf="activeStep == 1"> {{'Agent info' | translate}}</h4>
  <h4 class="modal-title" *ngIf="activeStep == 2"> {{'Set Commission Plan' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="popup('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<div id="step_1" [ngClass]="activeStep == 1 ? '' : 'hidden'">

  <form class="needs-validation" [formGroup]="AgentForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">
      <div class="col-md-6 form-group">
        <label for="FirstName">{{'First Name' | translate}}</label>
        <input type="text" formControlName="FirstName" class="form-control" id="FirstName"
               [ngClass]="{ 'is-invalid': submitted && f.FirstName.invalid }">
        <!--<div *ngIf="submitted && (f.FirstName.invalid || f.FirstName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>-->
      </div>
      <div class="col-md-6 form-group">
        <label for="SecondName">{{'Second Name' | translate}}</label>
        <input type="text" formControlName="SecondName" class="form-control" id="SecondName"
               [ngClass]="{ 'is-invalid': submitted && f.SecondName.invalid }">
       <!-- <div *ngIf="submitted && (f.SecondName.invalid || f.SecondName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>-->
      </div>
      <div class="col-md-6 form-group">
        <label for="LastName">{{'Last Name' | translate}}</label>
        <input type="text" formControlName="LastName" class="form-control" id="LastName"
               [ngClass]="{ 'is-invalid': submitted && f.LastName.invalid }">
       <!-- <div *ngIf="submitted && (f.LastName.invalid || f.LastName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>-->
      </div>
      <div class="col-md-6 form-group">
        <label for="SecondLastName">{{'Second Last Name' | translate}}</label>
        <input type="text" formControlName="SecondLastName" class="form-control" id="SecondLastName"
               [ngClass]="{ 'is-invalid': submitted && f.SecondLastName.invalid }">
        <!--<div *ngIf="submitted && (f.SecondLastName.invalid || f.SecondLastName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>-->
      </div>
      <div class="col-md-6 form-group">
        <label for="Username">{{'Username' | translate}}</label>
        <input type="text" formControlName="Username" class="form-control" id="Username" placeholder="Username"
               [ngClass]="{ 'is-invalid': submitted && f.Username.invalid }" required>
        <div *ngIf="submitted && (f.Username.invalid || f.Username.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Email">{{'Email' | translate}}</label>
        <input type="text" formControlName="Email" class="form-control" id="Email" placeholder="Email"
               [ngClass]="{ 'is-invalid': submitted && f.Email.invalid }" required>
        <div *ngIf="submitted && (f.Email.invalid || f.Email.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Password">{{'Password' | translate}}</label>
        <input type="text" formControlName="Password" class="form-control" id="Password" placeholder="Password"
               [ngClass]="{ 'is-invalid': submitted && f.Password.invalid }" required>
        <div *ngIf="submitted && (f.Password.invalid || f.Password.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Mobile">{{'Mobile' | translate}}</label>
        <input type="text" formControlName="Mobile" class="form-control" id="Mobile" placeholder="Mobile"
               [ngClass]="{ 'is-invalid': submitted && f.Mobile.invalid }">
       <!-- <div *ngIf="submitted && (f.Mobile.invalid || f.Mobile.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>-->
      </div>


      <div class="col-md-6 form-group">
        <label for="CountryId">{{'Country' | translate}}</label>
        <ng-select [items]="countriesList"
                   id="CountryId"
                   bindLabel="Name"
                   bindValue="Id"
                   formControlName="CountryId" [ngClass]="{ 'is-invalid': submitted && f.CountryId.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.CountryId.invalid || f.CountryId.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group">
        <label for="Role">{{'Role' | translate}}</label>
        <ng-select [items]="roleList"
                   id="Role"
                   bindLabel="name"
                   (change)="roleChanged($event)"
                   bindValue="id"
                   formControlName="Role" [ngClass]="{ 'is-invalid': submitted && f.Role.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.Role.invalid || f.Role.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group">
        <label for="Parent">{{'Parent' | translate}}</label>
        <ng-select [items]="agentsList"
                   bindLabel="name"
                   id="Parent"
                   bindValue="id"
                   formControlName="Parent" [ngClass]="{ 'is-invalid': submitted && f.Parent.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.Parent.invalid || f.Parent.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group" *ngIf="partnerId != 16">
        <label for="Currency">{{'Currency' | translate}}</label>
        <ng-select [items]="currenciesList"
                   bindLabel="name"
                   id="Currency"
                   bindValue="id"
                   formControlName="Currency" [ngClass]="{ 'is-invalid': submitted && f.Currency.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.Currency.invalid || f.Currency.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group" *ngIf="partnerId == 16">
        <label for="Currency1">{{'Currency' | translate}}</label>
        <input type="text" formControlName="Currency" class="form-control" id="Currency1" placeholder="Currency"
               [ngClass]="{ 'is-invalid': submitted && f.Currency.invalid }" required [attr.disabled]="true">
        <div *ngIf="submitted && (f.Currency.invalid || f.Currency.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary is-invalid" (click)="popup('Cross click');">{{'Close' | translate}}</button>
      <button class="btn btn-primary" type="submit" (click)="changeStep(2)">{{'Next' | translate}}</button>
    </div>
  </form>
</div>


<div id="Step_2" [ngClass]="activeStep == 2 ? '' : 'hidden'">

  <form class="needs-validation" [formGroup]="PlansForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">
      <div class="col-md-12 form-group">
        <label for="Plan">{{'Plan' | translate}}</label>
        <select formControlName="planId"
                (change)="planIdChangedTarget($event.target)"
                [ngClass]="{ 'is-invalid': submitted && plansF.planId.invalid }"
                class="form-control"
                id="Plan"
                placeholder="Plan Type"
                required >
          <ng-template let-item ngFor [ngForOf]="plansList">
            <option [value]="item.id">{{item.name}}</option>
          </ng-template>
        </select>
        <div *ngIf="submitted && (plansF.planId.invalid || plansF.planId.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>

      <div class="col-md-12 form-group" *ngIf="plan.name">
        <app-commission-card [dataItem]="plan" [hideTopIcons]="true"></app-commission-card>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary is-invalid" (click)="changeStep(1)">{{'Back' | translate}}</button>
      <button class="btn btn-primary" type="submit" (click)="createAgent()">{{'Create' | translate}}</button>
    </div>
  </form>
</div>



</div>
