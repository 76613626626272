import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../../../services/requests.service';
import {SettingsService} from '../../../../services/settings.service';
import {CoreService} from '../../../../services/core.service';
import { TransferOperationsEnum} from '../../../../enums/enums.enum';

@Component({
  selector: 'app-transfer-player',
  templateUrl: './transfer-player.component.html',
  styleUrls: ['./transfer-player.component.css']
})
export class TransferPlayerComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  @Input() playerId: any;
  public submitted = false;
  public transferForm: any = new FormGroup({
    player: new FormControl('', [Validators.required]),
    Amount: new FormControl('', [Validators.required]),
    Comment: new FormControl('', [Validators.required]),
    OperationType: new FormControl(String(TransferOperationsEnum.Deposit), [Validators.required])
  });


  public operationsEnum = TransferOperationsEnum;
  public playersList: Array<any> = [];
  public selectedPlayer = {
    balance: 0,
    credit: 0,
    id: 0,
    name: '',
    currencyId: '',
  };

  constructor(
    public Http: RequestsService,
    public Settings: SettingsService,
    public Core: CoreService
  ) {

    this.Http.httpGet(this.Settings.apiUrls.options.directPlayers)
      .subscribe((res: any) => {
        if (res.data) {
          this.playersList = res.data;
        }
      })
  }

  ngOnInit() {
    if (this.playerId) {
      this.playerChanged({id: this.playerId})
    }
    this.transferForm.patchValue({
      OperationType: this.Core.checkPermissions(['deposittoplayer']) ? String(TransferOperationsEnum.Deposit) : String(TransferOperationsEnum.Withdraw),
      player: this.playerId ? this.playerId : ''
    })
  }

  get f() {
    return this.transferForm.controls;
  }


  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.transferForm.invalid) {
      return;
    }

    this.Http.httpPost(`${this.Settings.apiUrls.player.player}${this.transferForm.value.player}/transfermoney`, this.transferForm.value, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.Core.showInfoMsg('Success');
          this.popup('Cross click')
        }
      })
  }

  playerChanged(ev: any) {
    if (ev) {
      this.Http.httpPost(this.Settings.apiUrls.player.player + ev.id, {}, true)
        .subscribe((res: any) => {
          if (res.data) {
            this.selectedPlayer = res.data;
          }
        })

    } else {
      this.selectedPlayer.id = 0;
    }
  }


  ngOnDestroy() {
  }
}
