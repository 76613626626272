<router-outlet *ngIf=" !settings.userData || settings.userData && settings.userData.status !== -1 && settings.userData.status !== -2"></router-outlet>
<ngx-spinner></ngx-spinner>
<div *ngIf="settings.userData && (settings.userData.status == -1 || settings.userData.status == -2)" class="userInfo-pending">
  <div style="width: 400px; margin: 0 auto">
    <div class="modal-header" >
      <h4 class="modal-title">{{'User Info' | translate}}</h4>
    </div>
    <div class="modal-body">

      <app-register-step></app-register-step>
    </div>
  </div>
</div>


<!--<div class="preview-frame"  [hidden]="!settings.showPreview">-->
<div class="preview-frame"  *ngIf="settings.showPreview">
  <span class="close-frame" (click)="settings.showPreview = false"><i class="ft-x-circle"></i></span>
  <iframe src="/pages/main" id="previewFrame"></iframe>
</div>




