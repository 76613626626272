import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RequestsService} from '../../../shared/services/requests.service';
import {CoreService} from '../../../shared/services/core.service';
import {SettingsService} from '../../../shared/services/settings.service';
import {AccountService} from '../../../shared/services/account.service';

@Component({
  selector: 'app-account-actions',
  templateUrl: './account-acctions.component.html'
})


export class AccountAcctionsComponent implements OnInit {
  public activeType = ''
  public cpaLiveTime = ''
  public revenueLiveTime = ''
  public flatFee = ''
  public partnerUrl = ''
  public domainsList: Array<any> = []
  @Input() agentInfo: any;

  constructor(
    private modalService: NgbModal,
    public http: RequestsService,
    public core: CoreService,
    public account: AccountService,
    public settings: SettingsService) {
  }


  ngOnInit() {
    this.cpaLiveTime = this.agentInfo.cpaLifeTime;
    this.revenueLiveTime = this.agentInfo.revenueLifeTime;
    this.flatFee = this.agentInfo.flatFee;
    this.partnerUrl = this.agentInfo.domainId;
    this.http.httpGet(this.settings.apiUrls.options.domains)
      .subscribe((res: any) => {
        if (res.data) {
          this.domainsList = res.data;
        }
      })
  }


  openActionPopup(modalEl: any, type: any) {
    switch (type) {
      case 'cpaLiveTime' :
        this.activeType = type;
        this.modalService.open(modalEl)
        break;
      case 'revenueLiveTime' :
        this.activeType = type;
        this.modalService.open(modalEl)
        break;
      case 'flatFee' :
        this.activeType = type;
        this.modalService.open(modalEl)
        break;
      case 'partnerUrl' :
        this.activeType = type;
        this.modalService.open(modalEl)
        break;
    }
  }

  save() {


    switch (this.activeType) {
      case 'cpaLiveTime' :
        this.http.httpPost(`${this.settings.apiUrls.agents.agent}${this.agentInfo.id}/cpa/lifetime`, {
          LifeTime: this.cpaLiveTime
        }).subscribe((res: any) => {
          if (res.data) {
            this.modalService.dismissAll();
            this.core.showInfoMsg('Updated')
          }
        })
        break;
      case 'revenueLiveTime' :
        this.http.httpPost(`${this.settings.apiUrls.agents.agent}${this.agentInfo.id}/revenue/lifetime`, {
          LifeTime: this.revenueLiveTime
        }).subscribe((res: any) => {
          if (res.data) {
            this.modalService.dismissAll();
            this.core.showInfoMsg('Updated')
          }
        })
        break;
      case 'flatFee' :
        this.http.httpPost(`${this.settings.apiUrls.agents.agent}${this.agentInfo.id}/flatfee`, {
          FlatFee: this.flatFee
        }).subscribe((res: any) => {
          if (res.data) {
            this.modalService.dismissAll();
            this.core.showInfoMsg('Updated')
          }
        })
        break;
      case 'partnerUrl' :
        this.http.httpPost(`${this.settings.apiUrls.agents.agent}${this.agentInfo.id}/domain`, {
          DomainId: this.partnerUrl
        }).subscribe((res: any) => {
          if (res.data) {
            this.modalService.dismissAll();
            this.core.showInfoMsg('Updated')
          }
        })
        break;
    }
  }
}
