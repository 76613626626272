import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CoreService} from '../../../services/core.service';

@Component({
  selector: 'app-transfer-money',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})

export class TransferComponent implements OnInit, OnDestroy {
  @ViewChild('customContent') customContent: any;
  public activeTransferFormType = 0;
  closeResult: string = '';
  public transferTypes = {
    Agent: '1',
    Player: '2'
  };
  public transferFormTypes = {
    hidden: 0,
    showPlayerForm: 1,
    showAgentForm: 2
  };

  public transferType:any = this.Core.checkPermissionsOr(['deposittoagent', 'agentwithdraw']) ? '1' : '2';
  public userId = 0;

  constructor(
    private modalService: NgbModal,
    public Core: CoreService
  ) {
  }

  ngOnInit() {
    this.Core.openTransferEvent.subscribe((ev) => {
      if (ev.type === 'agent') {
        this.openModal(this.customContent);
        this.activeTransferFormType = this.transferFormTypes.showAgentForm;
        this.userId = ev.userId;
      }
      if (ev.type === 'player') {
        this.openModal(this.customContent);
        this.activeTransferFormType = this.transferFormTypes.showPlayerForm;
        this.userId = ev.userId;
      }
    })
  }

  openModal(customContent: any) {
    this.activeTransferFormType = 0;
    this.modalService.open(customContent, {windowClass: 'dark-modal'});
    this.userId = 0;
  }

  showUserForm(type: any) {
    this.activeTransferFormType = type
  }


  ngOnDestroy() {
  }
}
