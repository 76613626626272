<div>
  <form class="needs-validation" [formGroup]="AgentForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">
      <div class="col-md-6 form-group">
        <label for="FirstName">{{'First Name' | translate}}</label>
        <input type="text" formControlName="FirstName" class="form-control" id="FirstName"
               [ngClass]="{ 'is-invalid': submitted && f.FirstName.invalid }">
        <div *ngIf="submitted && (f.FirstName.invalid || f.FirstName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="SecondName">{{'Second Name' | translate}}</label>
        <input type="text" formControlName="SecondName" class="form-control" id="SecondName"
               [ngClass]="{ 'is-invalid': submitted && f.SecondName.invalid }">
        <div *ngIf="submitted && (f.SecondName.invalid || f.SecondName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="LastName">{{'Last Name' | translate}}</label>
        <input type="text" formControlName="LastName" class="form-control" id="LastName"
               [ngClass]="{ 'is-invalid': submitted && f.LastName.invalid }">
        <div *ngIf="submitted && (f.LastName.invalid || f.LastName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="SecondLastName">{{'Second Last Name' | translate}}</label>
        <input type="text" formControlName="SecondLastName" class="form-control" id="SecondLastName"
               [ngClass]="{ 'is-invalid': submitted && f.SecondLastName.invalid }">
        <div *ngIf="submitted && (f.SecondLastName.invalid || f.SecondLastName.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Username">{{'Username' | translate}}</label>
        <input type="text" formControlName="Username" class="form-control" id="Username" placeholder="Username"
               [ngClass]="{ 'is-invalid': submitted && f.Username.invalid }" required>
        <div *ngIf="submitted && (f.Username.invalid || f.Username.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Email">{{'Email' | translate}}</label>
        <input type="text" formControlName="Email" class="form-control" id="Email" placeholder="Email"
               [ngClass]="{ 'is-invalid': submitted && f.Email.invalid }" required>
        <div *ngIf="submitted && (f.Email.invalid || f.Email.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
      <div class="col-md-6 form-group">
        <label for="Mobile">{{'Mobile' | translate}}</label>
        <input type="text" formControlName="Mobile" class="form-control" id="Mobile" placeholder="Mobile"
               [ngClass]="{ 'is-invalid': submitted && f.Mobile.invalid }">
        <div *ngIf="submitted && (f.Mobile.invalid || f.Mobile.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group">
        <label for="CountryId">{{'Country' | translate}}</label>
        <ng-select [items]="countriesList"
                   id="CountryId"
                   bindLabel="Name"
                   bindValue="Id"
                   formControlName="CountryId" [ngClass]="{ 'is-invalid': submitted && f.CountryId.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.CountryId.invalid || f.CountryId.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>


      <div class="col-md-6 form-group">
        <label for="Currency">{{'Currency' | translate}}</label>
        <ng-select [items]="currenciesList"
                   bindLabel="name"
                   id="Currency"
                   bindValue="id"
                   formControlName="Currency" [ngClass]="{ 'is-invalid': submitted && f.Currency.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.Currency.invalid || f.Currency.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="logout()">{{'logout' | translate}}</button>
      <button class="btn btn-primary" type="submit" (click)="createAgent()">{{'Update' | translate}}</button>
    </div>
  </form>
</div>
