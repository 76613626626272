import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsService} from '../../../services/settings.service';
import {CoreService} from '../../../services/core.service';

@Component({
  selector: 'app-partner-links',
  templateUrl: './partner-links.component.html'
})

export class PartnerLinksComponent  implements OnInit {
  @ViewChild('customContent') customContent: any;
  public referralLink = '';
  public partnerLink = '';
  showShortPartnerUrl = false;
  showShortReferralUrl = false;
  constructor(
    private modalService: NgbModal,
    private settings: SettingsService,
    private core: CoreService,
  ) {
  }


  openModal(customContent: any) {
    this.modalService.open(customContent, {windowClass: 'dark-modal'});
  }
  ngOnInit() {
    this.referralLink = this.settings.userData?.ReferralUrl;
    this.partnerLink = this.settings.userData?.partnerUrl;

    this.settings.openPartnerUrlsModal.subscribe((ev: any) => {
      if (ev){
        this.modalService.open(this.customContent, {windowClass: 'dark-modal'});
      }
    })
  }

  showSortUrl(field: any) {
    if (field === 'partner') {
      this.partnerLink = this.showShortPartnerUrl ? this.settings.userData?.shortPartnerUrl : this.settings.userData?.partnerUrl;
    }
    if (field === 'referral') {
      this.referralLink = this.showShortReferralUrl ? this.settings.userData?.shortReferralUrl : this.settings.userData?.ReferralUrl;
    }
  }

  copyText(elId: any) {
    const copyText: any = document.getElementById(elId);
    this.core.copyTextToClipboard(copyText.value)
    this.core.showInfoMsg(' Copied ' + elId);
  }
}
