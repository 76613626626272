
export enum ErrorList  {
  NotError = 0,
  GeneralError = 999,
  SessionNotFound = 1,
  SessionExpired = 2,
  WrongPlayerId = 3,
  PlayerBlocked = 4,
  LowBalance = 5,
  TrasnactionNotFound = 6,
  TrasnactionAlreadyExists = 7,
  ProviderNotAllowedForPartner = 8,
  ProviderNotFound = 9,
  GameNotFound = 10,
  WrongApiCredentials = 11,
  InvalidMethod = 12,
  AdminUserNotFound = 13,
  UsernameAlreadyExists = 14,
  AdminUserBlocked = 15,
  PartnerNotFound = 16,
  DocumentAlreadyRollbacked = 17,
  WrongPartnerId = 18,
  WrongCurrencyId = 19,
  PermissionNotAllowed = 20,
  PhoneAlreadyExists = 21,
  EmailAlreadyExists = 22,
  WrongPassword = 23,
  NotAuthorized = 24,
  WrongEmail = 25,
  WrongEmailOrPassword = 26,
  PersonalIdAlreadyExists = 27,
  WrongDepositAmount = 28,
  WrongWithdrawAmount = 29,
  WrongPaymentData = 30,
  PaymentRequestNotFound = 31,
  PaymentRequestAlreadyExist = 32,
  WrongToken = 33,
  PaymentMethodNotAvailable = 34,
  WithdrawTemproaryBlocked = 35,
  DepostTemproaryBlocked = 36,
  SamePassword = 37,
  WrongNewPassword = 38,
  WrongPaymentRequestId = 39,
  WithdrawRequestNotFound = 40,
  WithdrawRequestNotChangeable = 41,
  WrongWithdrawWequestState = 42,
  DuplicatedData = 43,
  WrongPhoneNumber = 44,
  PlayerAlreadyExists = 45,
  FavoriteGamesLimit = 46,
  LoyaltyNotFound = 47,
  LoyaltyRankNotFound = 48,
  LoyaltyPointNotFound = 49,
  LoyaltyRankCoefficientNotFound = 50,
  LoyaltyRankOrderAlreadyExist = 51,
  PartnerDontHaveLoyalty = 52,
  NotCompleteWager = 53,
  WagerNotFound = 54,
  LoyaltyRankBonusNotFound = 55,
  BonusNotFound = 56,
  BonusAlreadyActive = 57,
  YouHaveActiveBonus = 58,
  PaymentNotFound = 59,
  GameNotAllowedForPartner = 60,
  PlayerHaveNotDocuments = 61,
  PartnerAlreadyHaveLoyalty = 62,
  WrongInputParameters = 63,
  GameCategoryNotFound = 64,
  JackpotNotFound = 65,
  ObjectCannotBeActivated = 66,
  DocumentNumberAlreadyExist = 67,
  DocumentNotFound = 68,
  WrongLanguageId = 69,
  WrongDateTimeParameter = 70,
  SkinLanguageNotFound = 71,
  AgentHasNotPermission = 72,
  RoleNotFound = 73,
  AgentNotFound = 74,
  CommissionPlanNotFound = 75,
  SkinPromotionNotFound = 76,
  SkinPromotionTranslateNotFound = 77,
  SkinInactivePromotionNotPublishedYet = 78,
  SkinPromotionNotPublishedYet = 79,
  SkinPromotionTranslateAlreadyCreated = 80,
  SkinBannerNotFound = 81,
  SkinBannerTranslateNotFound = 82,
  SkinInactiveBannerNotPublishedYet = 83,
  SkinBannerNotPublishedYet = 84,
  SkinBannerTranslateAlreadyCreated = 85,
  SkinStaticContentNotFound = 86,
  SkinStaticContentTranslateNotFound = 87,
  SkinInactiveStaticContentNotPublishedYet = 88,
  SkinStaticContentNotPublishedYet = 89,
  SkinStaticContentTranslateAlreadyCreated = 90,
  LoyaltyTranslateAlreadyCreated = 91,
  LoyaltyRankTranslateAlreadyCreated = 92,
  LoyaltyTranslateNotFound = 93,
  LoyaltyRankTranslateNotFound = 94,
  SkinLanguageKeyExist = 95,
  BonusCompleted = 96,
  FreespinCurrencyDoesnotExist = 97,
  BonusActivationDisabled = 98,
  BonusNotAvailable = 99,
  BonusCanceled = 100,
  NotEnoughMoney = 101,
  BonusFreespinEditDisable = 102,
  WaitingWithdrawal = 103,
  EmailTemplateNotFound = 108,
  EmailTemplateTranslateNotFound = 109,
  EmailTemplateTranslateAlreadyCreated = 110,
  PlayerHaveTheSameBonusActive = 111,
  GameContentAlreadyInContent = 112,
  PaymentAlreadyInContent = 113,
  BonusNotAvaialbleForPlayer = 114,
  BonusCantBeCanceled = 115,
  BonusInactive = 116,
  BonusDoesnothaveAwards = 117,
  BonusExpired = 118,
  CountryNotFound = 119,
  ProviderGroupNotFound = 120,
  ProviderLanguageNotFound = 121,
  ProviderCurrencyNotFound = 122,
  WrongTwoFactorCode = 123,
  TwoFactorCodeRequired = 124,
  PlayerNotVerified = 125,

  NotPossibleToUpdateFields = 126,
  PaymentMethodNotFound = 127,
  AdditionalWebSiteUrlExist = 128,
  AdditionalWebSiteNotFound = 129,

  GroupWithNameAlreadyExists = 130,
  GroupNotFound = 131,
  AgentAlreadyInGroup= 132,
  AgentNotFoundInGroup = 133,

  LinkNotFound = 134,
  LinkWithNameExists = 135,
  BonusStateCantBeChanged = 136,
  WrongBonusState = 137,
  BonusAwardNotFound = 138,
  WrongBonusAmount = 139,
  ImageBannerNotAvailable = 140,
  VideoBannerNotAvailable = 141,
  CampaignNotFound = 142,
  ShortPathExists = 143,
}
