import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsService} from "../../../../services/settings.service";

@Component({
  selector: 'app-create-main',
  templateUrl: './create-main.component.html'
})
export class CreateMainComponent implements OnInit, OnDestroy {
  @ViewChild('customContent') customContent: any;
  public userTypes = {
    Agent: '1',
    Player: '2'
  };
  public userFormTypes = {
    hidden: 0,
    showPlayerForm: 1,
    showAgentForm: 2
  };

  public activeUserFormType = 0;
  public userType = '1';

  constructor(
    private modalService: NgbModal,
    public settings: SettingsService
  ) {
  }

  ngOnInit() {
    this.settings.openCreateUserModal.subscribe((ev: any) => {
      if (ev){
        this.openCreateFormByType(ev.type);
      }
    })
  }

  openModal(customContent: any) {
    this.activeUserFormType = 0;
    this.modalService.open(customContent, {windowClass: 'dark-modal'});
  }

  showUserForm(type: any) {
    this.activeUserFormType = type
  }

  public openCreateFormByType(type: any){
    this.modalService.open(this.customContent, {windowClass: 'dark-modal'});
    this.activeUserFormType = type === 'player' ? this.userFormTypes.showPlayerForm : this.userFormTypes.showAgentForm;
  }

  ngOnDestroy() {
  }
}
