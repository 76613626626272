import {FormControl, Validators} from '@angular/forms';

export class PlanModel {
  agents?: number;
  conds?: Array<any>;
  currency?: string;
  default?: false;
  deletable?: true;
  isShowRev?: boolean;
  editable?: true;
  id?: number;
  name?: string;
  product?: any;
  products?: any;
  rangeInfos?: null;
  state?: number;
  type?: number;
  ranges?: any;
  revenuePlan?: any;
  cpaPlan?: any;
}


export interface RegFormItem {
  id?: any;
  dbId?: any;
  hidden?: boolean;
  partnerId?: any;
  name?: any;
  field?: any;
  type?: any;
  order?: any;
  required?: any;
  CreateDate?: any;
  LastUpdateDate?: any;
  state?: any;
  stepNumber?: any;
  isDefault?: any;
}


export interface ApiFormItem {
  Id?: number
  IsDefault?: boolean
  Order?: number
  Required?: boolean
  StepNumber?: any
  Type?: number
}


export interface ContentItem {
  Id?: number;
  LanguageId?: string
  State?: number
  Translate?: string
  Type?: string
}

export interface FeatureItem {
  LangId?: string,
  Title?: string,
  Description?: string,
}

export interface TestimonialItem {
  LangId?: string,
  Reviewer?: string,
  LinkName?: string,
  Link?: string,
  Review?: string,
}

export interface FaqItem {
  LangId?: string,
  Question?: string,
  showItem?: boolean,
  Answer?: string,
}


export interface PaymentItemModel {
  'Id'?: number,
  'Name'?: string,
  'Image'?: string,
  'fields'?: string,
  'State'?: number,
  'CurrencyId'?: string,
  'MinAmount'?: number,
  'MaxAmount'?: number
}

export interface CoreBonusModel {
  Id?: number,
  Code?: string,
  Name?: string,
  Description?: string,
  ShortDescription?: string,
  PerAgentPlayerCount?: number,
  TotalPlayerCount?: number,
  ExpireDate?: string,
  Type?: number,
  CreateDate?: string,
  State?: number,
  IsForSale?: boolean,
  CurrencyId?: string,
  Price?: number,
  AgentId?: number,
  AgentGroupId?: number,
  AgentUsername?: string,
  AgentGroupName?: string,
}

export interface PermissionModel {
  Id?: number,
  PermissionId?: number,
  GroupId?: number,
  Name?: string,
  Description?: string,
  Key?: string,
  ShareState?: boolean,
  OwnState?: boolean
}

export interface PermissionGroupModel {
  Id?: number,
  Name?: string,
  Description?: string,
  showChield?: boolean,
  permissionChecked?: boolean,
  permissionIndeterminate?: boolean,
  sharedChecked?: boolean,
  sharedIndeterminate?: boolean,
  Permissions?: Array<PermissionModel>
}

export interface RoleModel {
  id?: number,
  name?: string,
  abv?: string,
  parentId?: number,
  creator?: string,
  isDefault?: boolean,
  showChield?: boolean,
  groups?: Array<PermissionGroupModel>
}



