export enum AgentCommissionPlanTypes {
  Revenue = 1,
  Deposit = 2,
  PlayersCount = 3,
}

export const AgentCommissionPlanTypesList = [
  {Id: '1', Name: 'Revenue'},
  {Id: '2', Name: 'Net Deposit'},
  {Id: '3', Name: 'Players Count'},
];

export enum ACPActionTypes {
  Equal = 1,
  NotEqual = 2,
  Less = 3,
  LessOrEqual = 4,
  Greater = 5,
  GreaterOrEqual = 6,
}

export const ACPActionTypesList = [
  {Id: 1, Name: 'Equal', Icon: '='},
  {Id: 2, Name: 'Not Equal', Icon: '!='},
  {Id: 3, Name: 'Less', Icon: '<'},
  {Id: 4, Name: 'Less Or Equal', Icon: '<='},
  {Id: 5, Name: 'Greater', Icon: '>'},
  {Id: 6, Name: 'Greater Or Equal', Icon: '>='},
];

export enum ACPConditionTypes {
  Deposit = 1,
  GGR = 2,
  Bet = 3,
}

export enum Products {
  SlotGames = 1,
  LiveCasino = 2,
  BettingGames = 3,
  VirtualGames = 4,
  SportBetting = 5,
  TableGames = 8,
  TVGames = 9,
}

export enum OverBetProducts {
  SlotGames = 1,
  LiveCasino = 2,
  P2P = 3,
  VirtualGames = 4,
  SportBetting = 5,
  TurboGames = 6,
  Poker = 7,
  TableGames = 8,
  TVGames = 9,
  VideoPoker = 10,
  VirtualSports = 11,
  LottoGames = 12,
}

export const ProductsList = [
  {Id: 1, Name: 'Slot Games'},
  {Id: 2, Name: 'Live Casino'},
  {Id: 3, Name: 'Betting Games'},
  {Id: 4, Name: 'Virtual Games'},
  {Id: 5, Name: 'Sport Betting'},
  {Id: 8, Name: 'Table Games'},
  {Id: 9, Name: 'TV Games'},
];

export const OverBetProductsList = [
  {Id: 1, Name: 'Slot Games'},
  {Id: 2, Name: 'Live Casino'},
  {Id: 3, Name: 'P2P'},
  {Id: 4, Name: 'Virtual Games'},
  {Id: 5, Name: 'Sport Betting'},
  {Id: 6, Name: 'Turbo Games'},
  {Id: 7, Name: 'Poker'},
  {Id: 8, Name: 'Table Games'},
  {Id: 9, Name: 'TV Games'},
  {Id: 10, Name: 'Video Poker'},
  {Id: 11, Name: 'Virtual Sports'},
  {Id: 12, Name: 'LottoGames'}
];


export enum AgentPermissions {
  BlockPlayer = 'blockplayer',
  ChangeChildCommissionPlan = 'changechildcommissionplan',
  ChangeAgentPassword = 'changeagentpassword',
  ChangePlayerPassword = 'changeplayerpassword',
  DepositToAgent = 'deposittoagent',
  DepositToPlayer = 'deposittoplayer',
  RegisterPlayer = 'registerplayer',
  AgentWithdraw = 'agentwithdraw',
  PlayerWithdraw = 'playerwithdraw',
  CreateCommissionPlan = 'createcommissionplan',
}

export const AgentPermissionsList = [
  {Id: 1, Name: 'Block Player'},
  {Id: 1, Name: 'Change Child Commission Plan'},
  {Id: 1, Name: 'Change Agent Password'},
  {Id: 1, Name: 'Change Player Password'},
  {Id: 1, Name: 'Deposit ToAgent'},
  {Id: 1, Name: 'Deposit To Player'},
  {Id: 1, Name: 'Register Player'},
  {Id: 1, Name: 'Agent Withdraw'},
  {Id: 1, Name: 'Player Withdraw'},
  {Id: 1, Name: 'Create Commission Plan'},
];


export const PlayersCountPlanObj = {
  name: 'Range For Players Count',
  type: AgentCommissionPlanTypes.PlayersCount,
  ranges: [
    {min: 0, max: 10, percent: 25},
    {min: 10, max: 40, percent: 30},
    {min: 40, max: '∞', percent: 35},
  ]
};

export const RevenuePlanObj = {
  name: 'Range For Revenue',
  type: AgentCommissionPlanTypes.Revenue,
  ranges: [
    {min: 0, max: 2500, percent: 25},
    {min: 2500, max: 10000, percent: 30},
    {min: 10000, max: '∞', percent: 35},
  ]
};

export const TurnoverPlanObj = {
  name: 'Range For Bet Amount',
  type: AgentCommissionPlanTypes.Revenue,
  ranges: [
    {min: 0, max: 2500, percent: 25},
    {min: 2500, max: 10000, percent: 30},
    {min: 10000, max: '∞', percent: 35},
  ]
};

export const DepositPlanObj = {
  name: 'Range For Deposit',
  type: AgentCommissionPlanTypes.Deposit,
  ranges: [
    {min: 0, max: 2500, percent: 25},
    {min: 2500, max: 10000, percent: 30},
    {min: 10000, max: '∞', percent: 35},
  ]
};

export enum AgentMessageStatesEnum {
  Sent = 1,
  Unread = 2,
  Read = 3,
  Removed = 4,
}

export enum AgentMessageTypesEnum {
  Information = 0,
  Important = 1,
  Technical = 2,
}

export const AgentMessageTypesList = [
  {id: 0, name: 'Information', class: 'warning'},
  {id: 1, name: 'Important', class: 'danger'},
  {id: 2, name: 'Technical', class: 'success'},
];

export const MessageTypesColors = {
  0: 'warning',
  1: 'danger',
  2: 'success',
};


export enum AgentStatusEnum {

  PendingUnverifiedEmail = -2,
  Pending = -1,
  Passive = 0,
  Active = 1,
  Blocked = 2,
}

export const AgentStatusList = [
  {id: 0, name: 'Passive'},
  {id: 1, name: 'Active'},
  {id: 2, name: 'Blocked'},
];


export const PendingAgentStatusList = [
  {id: -2, name: 'Pending Unverified Email'},
  {id: -1, name: 'Pending'},
];

export enum OperationEnum {
  EqualTo = 0,
  Contains = 1,
  StartsWith = 2,
  EndsWith = 3,
  NotEqualTo = 4,
  GreaterThan = 5,
  GreaterThanOrEqualTo = 6,
  LessThan = 7,
  LessThanOrEqualTo = 8,
  Between = 9,
  IsNull = 10,
  IsEmpty = 11,
  IsNullOrWhiteSpace = 12,
  IsNotNull = 13,
  IsNotEmpty = 14,
  IsNotNullNorWhiteSpace = 15,
}

export enum FilterOperationTypes {
  eq = 0,
  contains = 1,
  startswith = 2,
  endswith = 3,
  neq = 4, // NotEqualTo
  gt = 5,
  gte = 6,
  lt = 7,
  lte = 8,
  Between = 9,
  isnull = 10,
  isempty = 11,
  IsNullOrWhiteSpace = 12,
  isnotnull = 13,
  isnotempty = 14,
  IsNotNullNorWhiteSpace = 15,
  In = 16
}

export enum AgentBalanceTypeEnum {
  Real = 1,
  Credit = 2,
  Commission = 3
}


export enum TransferOperationsEnum {
  Deposit = 4,
  Withdraw = 5
}


export enum RolesEnum {
  ContinentalManager = 2,
  CountryManager = 3,
  RegionalManager = 4,
  CityManager = 5,
  AreaManager = 6,
  Agent = 7,
  View = 8,
}

export enum ACPCalculationTypes {
  ByPlayer = 1,
  ByTotal = 2,
}


export const BannerTypes = {
  1: 'Popup',
  2: 'Fixed',
  3: 'Standard',
}

export const EMailTemplateVariables = [
  {
    id: '1',
    name: 'Name',
    key: '{name}',
  },
  {
    id: '2',
    name: 'Partner Site Url',
    key: '{url}',
  },
  {
    id: '2',
    name: 'Partner Affiliate Site Url',
    key: '{affiliateUrl}',
  },
  {
    id: '3',
    name: 'Token Url',
    key: '{tokenLink}',
  },
  {
    id: '4',
    name: 'User Full Name',
    key: '{userFullName}',
  },
  {
    id: '5',
    name: 'Status',
    key: '{status}',
  }
]


export const LangList = [
  {id: 'en', name: 'English'},
  {id: 'am', name: 'Armenian'},
  {id: 'ru', name: 'Russian'},
  {id: 'pl', name: 'Polish'},
];


export const SocialsList = [
  {
    Id: 1,
    Name: 'Facebook',
    Url: 'https://facebook.com/',
    Icon: 'Facebook.svg'
  },
  {
    Id: 2,
    Name: 'Twitter',
    Url: 'https://Twitter.com/',
    Icon: 'Twitter.svg'
  },
  {
    Id: 3,
    Name: 'Vkontakte',
    Url: 'https://Vkontakte.com/',
    Icon: 'Vkontakte.svg'
  },
  {
    Id: 4,
    Name: 'Youtube',
    Url: 'https://Youtube.com/',
    Icon: 'Youtube.svg'
  },
  {
    Id: 5,
    Name: 'Odnoklassniki',
    Url: 'https://Odnoklassniki.com/',
    Icon: 'Odnoklassniki.svg'
  },
  {
    Id: 6,
    Name: 'Vimeo',
    Url: 'https://Vimeo.com/',
    Icon: 'Vimeo.svg'
  },
  {
    Id: 7,
    Name: 'Telegram',
    Url: 'https://Telegram.com/',
    Icon: 'Telegram.svg'
  },
  {
    Id: 8,
    Name: 'Instagram',
    Url: 'https://Instagram.com/',
    Icon: 'Instagram.svg'
  },
  {
    Id: 9,
    Name: 'Linkedin',
    Url: 'https://Linkedin.com/',
    Icon: 'Linkedin.svg'
  }
]

export enum SocialsEnum {
  Facebook = 1,
  Twitter = 2,
  Vkontakte = 3,
  Youtube = 4,
  Odnoklassniki = 5,
  Vimeo = 6,
  Telegram = 7,
  Instagram = 8,
  Linkedin = 9,
}

export enum ContentTypesEnum {
  AboutUs = 1,
  Help = 2,
  Terms = 3,
  FAQ = 4,
  Testimonials = 5,
  Slogan = 6,
  type1 = 7,
  type2 = 8,
}

export enum TemplateTypeEnum {
  EmailRegistration = 101,
  EmailPasswordReset = 102,
  EmailSuccessfullReset = 103,
  EmailPasswordChange = 104,
  EmailUserBlock = 105,
  EmailUserActivation = 106,
  EmailProcessedWithdrawal = 107,
  EmailDeniedWithdrawal = 108,
  InternalRegistration = 201,
  InternalPasswordReset = 202,
  InternalSuccessfullReset = 203,
  InternalPasswordChange = 204,
  InternalUserBlock = 205,
  InternalUserActivation = 206,
  InternalProcessedWithdrawal = 207,
  InternalDeniedWithdrawal = 208,
}

export const TemplateTypeList = [
  {Name: 'EmailRegistration', Id: 101},
  {Name: 'EmailPasswordReset', Id: 102},
  {Name: 'EmailSuccessfullReset', Id: 103},
  {Name: 'EmailPasswordChange', Id: 104},
  {Name: 'EmailUserBlock', Id: 105},
  {Name: 'EmailUserActivation', Id: 106},
  {Name: 'EmailProcessedWithdrawal', Id: 107},
  {Name: 'EmailDeniedWithdrawal', Id: 108},
  {Name: 'InternalRegistration', Id: 201},
  {Name: 'InternalPasswordReset', Id: 202},
  {Name: 'InternalSuccessfullReset', Id: 203},
  {Name: 'InternalPasswordChange', Id: 204},
  {Name: 'InternalUserBlock', Id: 205},
  {Name: 'InternalUserActivation', Id: 206},
  {Name: 'InternalProcessedWithdrawal', Id: 207},
  {Name: 'InternalDeniedWithdrawal', Id: 208},
]


export const PaymentStates = {
  0: 'pending',
  1: 'In Review',
  2: 'Denied',
  3: 'Processed',

}

export enum PaymentStatesEnum {
  Pending = 0,
  Confirm = 1,
  Denied = 2,
  Processed = 3,
}


export const PaymentStatesList = [
  {
    Id: 1, Name: 'In Review'
  },
  {
    Id: 2, Name: 'Rejected'
  },
]


export enum CreateLinkEnum {
  Source = 1,
  Target = 2
}

export enum BonusStatesEnum {
  Deleted = 0,
  Active = 1,
  Passive = 2,
  Completed = 3,
}

export enum BonusTypesEnum {
  Deposit = 1,
  Money = 2,
  Freespin = 3,
  Freebet = 4,
  CampaignPromo = 5,
}

export enum CampaignState {
  Deleted = 0,
  Active = 1,
  Completed = 3,
}


export enum CommissionPlanSubTypes {
  Revenue = 1,
  NetDeposit = 2,
  PlayersCount = 3,
  TurnOver = 4,
}

export const CommissionPlanSubTypesList = [
  {Id: 1, Name: 'Revenue'},
  {Id: 2, Name: 'NetDeposit'},
  {Id: 3, Name: 'PlayersCount'},
  {Id: 4, Name: 'TurnOver'},
]


export enum CommissionPlanTypes {
  Revenue = 1,
  CPA = 2,
  Referral = 3,
  Hybrid = 4,
}

export enum CommissionPlanConditionTypes {
  Deposit = 1,
  GGR = 2,
  Bet = 3,
  FirstDeposit = 4,
}

export const ACPConditionTypesList = [
  {Id: 1, Name: 'Deposit'},
  {Id: 2, Name: 'GGR'},
  {Id: 3, Name: 'Bet'},
];
export const CommissionPlanConditionList = [
  {Id: 1, Name: 'Deposit'},
  {Id: 2, Name: 'GGR'},
  {Id: 3, Name: 'Bet'},
  {Id: 4, Name: 'First Deposits Count'},
];

export enum PeriodTypesEnum {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export const PeriodTypesList = [
  {Id: 1, Name: 'Day'},
  {Id: 2, Name: 'Week'},
  {Id: 3, Name: 'Month'},
  {Id: 4, Name: 'Year'},
];

export enum BetOperationTypesEnum {
  Bet = 1,
  Win = 2,
  Refund = 3,
  Deposit = 4,
  Withdraw = 5,
  BonusCancel = 8,
  BonusAward = 9,
  BonusBet = 11,
  BonusWin = 12,
  BonusRefund = 13,
  CorrectionDepost = 14,
  CorrectionWithdrawal = 15,
  Expenses = 17,
  Correction = 18,
  ChargeBack = 19,
  Payout = 20,
  Commission = 21,
  FlatFee = 22,
}

export const BetOperationTypesList = [
  {name: 'Bet', id: 1},
  {name: 'Win', id: 2},
  {name: 'Refund', id: 3},
  {name: 'Deposit', id: 4},
  {name: 'Withdraw', id: 5},
  {name: 'BonusCancel', id: 8},
  {name: 'BonusAward', id: 9},
  {name: 'BonusBet', id: 11},
  {name: 'BonusWin', id: 12},
  {name: 'BonusRefund', id: 13},
  {name: 'CorrectionDepost', id: 14},
  {name: 'CorrectionWithdrawal', id: 15},
  {name: 'Expenses', id: 17},
  {name: 'Correction', id: 18},
  {name: 'ChargeBack', id: 19},
  {name: 'Payout', id: 20},
  {name: 'Commission', id: 21},
  {name: 'FlatFee', id: 22},
]



export const TimeZoneOffsets = [
  { id: "660", name: "GMT-11:00" },
  { id: "600", name: "GMT-10:00" },
  { id: "540", name: "GMT-09:00" },
  { id: "480", name: "GMT-08:00" },
  { id: "420", name: "GMT-07:00" },
  { id: "360", name: "GMT-06:00" },
  { id: "300", name: "GMT-05:00" },
  { id: "270", name: "GMT-04:30" },
  { id: "240", name: "GMT-04:00" },
  { id: "210", name: "GMT-03:30" },
  { id: "180", name: "GMT-03:00" },
  { id: "120", name: "GMT-02:00" },
  { id: "60", name: "GMT-01:00" },
  { id: "0", name: "GMT+00:00" },
  { id: "-60", name: "GMT+01:00" },
  { id: "-120", name: "GMT+02:00" },
  { id: "-180", name: "GMT+03:00" },
  { id: "-210", name: "GMT+03:30" },
  { id: "-240", name: "GMT+04:00" },
  { id: "-270", name: "GMT+04:30" },
  { id: "-300", name: "GMT+05:00" },
  { id: "-330", name: "GMT+05:30" },
  { id: "-345", name: "GMT+05:45" },
  { id: "-360", name: "GMT+06:00" },
  { id: "-420", name: "GMT+07:00" },
  { id: "-480", name: "GMT+08:00" },
  { id: "-540", name: "GMT+09:00" },
  { id: "-570", name: "GMT+09:30" },
  { id: "-600", name: "GMT+10:00" },
  { id: "-660", name: "GMT+11:00" },
  { id: "-720", name: "GMT+12:00" },
  { id: "-780", name: "GMT+13:00" },
];


export const PeriodTypes = [
  {id: 'Today', name: 'Today'},
  {id: 'Yesterday', name: 'Yesterday'},
  {id: 'Wik', name: 'This Week'},
  {id: 'L7D', name: 'Last 7 Days'},
  {id: 'L30D', name: 'Last 30 Days'},
  {id: 'ThisMonth', name: 'This Month'},
  {id: 'custom', name: 'Custom'},
]
