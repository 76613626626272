import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AgentMessageTypesList} from '../../../enums/enums.enum';
import {RequestsService} from '../../../services/requests.service';
import {SettingsService} from '../../../services/settings.service';
import {ChatInvokeService} from '../../../services/signalr/chat-invoke.service';
import {CoreService} from '../../../services/core.service';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css']
})

export class SendMessageComponent implements OnInit, OnDestroy {
  @Input() receiverId: any;
  @Input() subject: any;
  @Input() type: any;
  @Input() isReplay = false;
  @Input() popup: any;
  @Output() closeEvent = new EventEmitter();
  public agentsList: Array<any> = [];
  public typeList = AgentMessageTypesList;
  public submitted = false;
  public sendForm: any = new FormGroup({
    type: new FormControl('', [Validators.required]),
    senderId: new FormControl(''),
    receiverId: new FormControl('', [Validators.required]),
    body: new FormControl('<div></div>', [Validators.required]),
    subject: new FormControl('', [Validators.required]),
  });

  constructor(
    public Http: RequestsService,
    public Settings: SettingsService,
    public MsgService: ChatInvokeService,
    public zone: NgZone,
    public Core: CoreService
  ) {

    this.Http.httpGet(this.Settings.apiUrls.options.agents)
      .subscribe((res: any) => {
        if (res.data) {
          this.agentsList = res.data;
        }
      })
  }

  ngOnInit() {
    this.Settings.sigCon.hubProxy.on('messageSent', (ev: any) => {
      if (ev) {
        this.zone.run(() => {
          this.Core.hideSpinner();
          this.Core.showInfoMsg('Message sent');
          if (this.popup) {
            this.popup('Close')
          }
          if (this.isReplay) {
            this.sendForm.patchValue({
              body: '',
            });
          } else {
            this.sendForm.patchValue({
              receiverId: '',
              subject: '',
              type: '',
              body: '',
            });
          }


        })

      }
    });
    if (this.receiverId) {
      this.sendForm.patchValue({
        receiverId: this.receiverId
      });
    }
    if (this.subject) {
      this.sendForm.patchValue({
        subject: this.subject,
      });
    }
    if (this.type || this.type == 0) {
      this.sendForm.patchValue({
        type: this.type,
      });
    }
  }

  get f() {
    return this.sendForm.controls;
  }

  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.sendForm.invalid) {
      return;
    }

    this.Core.showSpinner();
    this.MsgService.sendMsg(this.sendForm.value);


  }

  cancel() {
    this.closeEvent.emit(true);
  }

  ngOnDestroy() {
  }
}
