<div class="flex container">
  <div class="block_left block_cont">
    <div class="lang_filter bg flex space_btw ">
      <p class="lang_txt">{{'Possible' | translate}} {{name}}</p>
      <p class="lang_filter flex middle">
        <span class="filter_ic" (click)="checkUnChkAll(listType.left, false)"><i class="fa fa-list"></i></span>
        <span class="filter_ic"  (click)="checkUnChkAll(listType.left, true)"><i class="fa fa-tasks"></i></span>
        <span class="filter_ic" (click)="copyFromTo(listType.left)"><i class="fa fa-chevron-right"></i></span>
      </p>
    </div>
    <div class="bg search_cont">
      <input type="search" class="search_lang" placeholder="search {{name}}" [(ngModel)]="leftFilter">
      <ul class="lang_list">
        <ng-template let-item ngFor [ngForOf]="leftList | search:'name':leftFilter" let-index="index">
          <li class="lang_row flex middle"  *ngIf="!item.isDefault && !item.hidden">
            <input type="checkbox" [id]="'check'+item.id" class="lang_check"  [(ngModel)]="item.Selected">
            <label [for]="'check'+item.id">{{item.name}}</label>
            <span class="filter_ic filter_ic_lang" (click)="this.leftToRight(item, index)"><i
              class="fa fa-chevron-right"></i></span>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>


  <div class="block_right block_cont">
    <div class="lang_filter bg flex space_btw ">
      <p class="lang_txt">{{'Selected' | translate}} {{name}}</p>
      <p class="lang_filter flex middle">
        <span class="filter_ic" (click)="checkUnChkAll(listType.right, false)"><i class="fa fa-list"></i></span>
        <span class="filter_ic" (click)="checkUnChkAll(listType.right, true)"><i class="fa fa-tasks"></i></span>
        <span class="filter_ic" (click)="copyFromTo(listType.right)"><i class="fa fa-chevron-left"></i></span>
      </p>
    </div>
    <div class="bg search_cont">
      <input type="search" class="search_lang" placeholder="search {{name}}" [(ngModel)]="rightFilter">
      <ul class="my_lang_list">
        <ng-template let-item ngFor [ngForOf]="defList">
          <li class="my_lang_row active_lang">{{item.name}}</li>
        </ng-template>
      </ul>

      <ul class="lang_list lang_list_reverse">
        <ng-template let-item ngFor [ngForOf]="rightList | search:'name':rightFilter" let-index="index">
          <li class="my_lang_row active_lang" *ngIf="item.isDefault">{{item.name}}</li>
          <li class="lang_row flex middle"  *ngIf="!item.isDefault">
            <span class="filter_ic filter_ic_reverse" (click)="rightToLeft(item, index)"><i class="fa fa-chevron-left"></i></span>
            <input type="checkbox" [id]="'check'+item.id" class="lang_check"   [(ngModel)]="item.Selected">
            <label  [for]="'check'+item.id">{{item.name}}</label>
          </li>
        </ng-template>
      </ul>
    </div>

  </div>

</div>
