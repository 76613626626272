<div class="card bg-transparent shadow-none quill-wrapper m-0">
  <div class="card-header">
    <h3 class="card-title">{{'New Message' | translate}}</h3>
  </div>
  <!-- form start -->
  <form id="compose-form"  [formGroup]="sendForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="card-content">
      <div class="card-body">

        <div class="form-group" *ngIf="!subject">
          <label for="type">{{'Message Type' | translate}}</label>
          <ng-select [items]="typeList"
                     id="type"
                     bindLabel="name"
                     bindValue="id"
                     placeholder="Message Type"
                     formControlName="type"
                     [ngClass]="{ 'is-invalid': submitted && f.type.invalid }" required>
          </ng-select>
          <div *ngIf="submitted && (f.type.invalid || f.type.errors?.required)" class="invalid-tooltip">Required</div>
        </div>

        <div class="form-group" *ngIf="!receiverId">
          <label for="agents">{{'Agent' | translate}}</label>
          <ng-select [items]="agentsList"
                     id="agents"
                     bindLabel="name"
                     bindValue="id"
                     placeholder="Agent"
                     formControlName="receiverId"
                     [ngClass]="{ 'is-invalid': submitted && f.receiverId.invalid }" required>
          </ng-select>
          <div *ngIf="submitted && (f.receiverId.invalid || f.receiverId.errors?.required)" class="invalid-tooltip">Required</div>
        </div>
        <div class="form-group" *ngIf="!this.subject">
          <label class="sr-only" for="compose-email-subject">{{'Subject' | translate}}</label>
          <input type="text" id="compose-email-subject" class="form-control" placeholder="Subject" formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.invalid }" required>
          <div *ngIf="submitted && (f.subject.invalid || f.subject.errors?.required)" class="invalid-tooltip">Required</div>
        </div>

        <!-- Compose mail Quill editor -->
        <div class="snow-container border rounded">

          <quill-editor customToolbarPosition="bottom" format="html"  formControlName="body"  [ngClass]="{ 'is-invalid': submitted && f.body.invalid }">
            <div quill-editor-toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold" [title]="'Bold'"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-link"></button>
                    <button class="ql-image"></button>
                  </span>
            </div>
          </quill-editor>


        </div>
       <!-- <div class="form-group mt-2">
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="compose-email-attach">
            <label class="custom-file-label" for="compose-email-attach">Attach file</label>
          </div>
        </div>-->
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end pt-0">
      <button (click)="cancel()" class="btn bg-light-secondary compose-cancel-btn mr-2">
        <i class='ft-x m-0 mr-sm-1'></i>
        <span class="d-sm-inline-block d-none">{{'Cancel' | translate}}</span>
      </button>
      <button type="submit" class="btn-send btn btn-primary">
        <i class='ft-send m-0 mr-sm-1'></i>
        <span class="d-sm-inline-block d-none">{{'Send' | translate}}</span>
      </button>
    </div>
  </form>
  <!-- form start end-->
</div>
