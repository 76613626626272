
<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper navbar_wrapper_new">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen (click)="ToggleClass()">
            <i class=" {{toggleClass}} font-medium-3"></i>
          </a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="User" tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems) as result">
                  <app-option #searchResults *ngFor="let option of result"  [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"   >
                    <div class="d-flex align-items-center justify-content-between w-100"
                         [routerLink]="option.type===1 ? '/account/' + option.id  +'/account': '/player/' + option.id  +'/account'" *ngIf="searchOpenClass">
                      <div class="d-flex align-items-center justify-content-start">
                        <!--<i class="{{option.icon}} mr-2"></i>-->
                        <span *ngIf="option.type===1">{{'User' | translate}}: </span>
                        <span *ngIf="option.type===2">{{'Player' | translate}}: </span>
                        <span>{{ option.userName }} ({{ option.id }})</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length && searchOpenClass">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="User logo" [src]="logoUrl"></div><span
                    class="text">007</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav" *ngIf="Settings.userData" style="display: flex; flex-direction: row; align-items: center;">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"  href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <img class="langimg selected-flag" [src]="selectedLanguageFlag" alt="flag" />
              <span class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')">
                <img class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" />
                <span class="font-small-3">English</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('es')">
                <img class="langimg mr-2" src="./assets/img/flags/es.png" alt="flag" />
                <span class="font-small-3">Spain</span>
              </a>
            </div>
          </li>
          <li class=" nav-item mr-2 nav_link_new createUserIconBlock">
            <app-create-main></app-create-main>
          </li>
          <li class=" nav-item mr-2 nav_link_new transferUserIconBlock" *ngIf="core.checkPermissionsOr(['deposittoagent', 'deposittoplayer', 'agentwithdraw', 'playerwithdraw'])">
            <app-transfer-money></app-transfer-money>
          </li>

          <li class=" nav-item mr-2 nav_link_new partnerUrlsIconBlock">
            <app-partner-links></app-partner-links>
          </li>
          <li class="dropdown nav-item mr-1 userBalanceIconBlock" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end nav_link_new"
               id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>

              <div class="mr-2">
                <i  class="icon-wallet" style="    font-size: 24px;"></i>
              </div>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{'Balance' | translate}}</span>
                <span class="text-right text_header font-small-3">
                  {{(Settings.userData.wallet.balance + Settings.userData.wallet.credit) | currency: Settings.userData.wallet.currency}}
                </span>
              </div>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0 " aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="icon-wallet mr-2"></i>
                  <div class="user d-md-flex d-none mr-2">
                    <span class="text-right">{{'Balance' | translate}}</span>
                    <span class="text-right text-muted font-small-3">
                      {{Settings.userData.wallet.balance  | currency: Settings.userData.wallet.currency}}
                    </span>
                  </div>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <!--<a class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="icon-wallet mr-2"></i>
                  <div class="user d-md-flex d-none mr-2">
                    <span class="text-right">{{'Credit' | translate}}</span>
                    <span class="text-right text-muted font-small-3">
                      {{Settings.userData.wallet.credit | number: '1.02' | currency: Settings.userData.wallet.currency}}
                    </span>
                  </div>
                </div>
              </a>-->
            </div>
          </li>
          <li class="dropdown nav-item mr-1 " placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end nav_link_new" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{Settings.userData.name}} {{Settings.userData.surname}}</span><span
                  class="text-right text_header font-small-3">{{Settings.userData.username}}</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0 " aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
             <!-- <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>-->
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="icon-wallet mr-2"></i>
                  <span>
                    {{(Settings.userData.wallet.balance + Settings.userData.wallet.credit) | currency: Settings.userData.wallet.currency}}
                  </span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item"  (click)="openCreateModal('agent')">
                <div class="d-flex align-items-center">
                  <i class="ft-user-plus mr-2"></i><span>{{'Create User' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="openCreateModal('player')">
                <div class="d-flex align-items-center">
                  <i class="ft-user-plus mr-2"></i><span>{{'Create Player' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="openTransferModal('player')"   *ngIf="core.checkPermissionsOr(['deposittoplayer', 'playerwithdraw'])">
                <div class="d-flex align-items-center">
                  <i class="fa fa-exchange mr-2"></i><span>{{'Transfer To Player' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="openTransferModal('agent')" *ngIf="core.checkPermissionsOr(['deposittoagent', 'agentwithdraw'])">
                <div class="d-flex align-items-center">
                  <i class="fa fa-exchange mr-2"></i><span>{{'Transfer To Agent' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="openPartnerUrlsModal()">
                <div class="d-flex align-items-center">
                  <i class="ft-link mr-2"></i><span>{{'Partner Urls' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="'/account/' + Settings.userData.id + '/account'">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>{{'View Profile' | translate}}</span>
                </div>
              </a>
             <!-- <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>{{'My Inbox' | translate}}</span>
                </div>
              </a>-->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logOut()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{'Logout' | translate}}</span>
                </div>
              </a>
            </div>
          </li>

         <!-- <li class="nav-item mr-2 nav_link_new right-menu-block">
            <i class="ft-more-vertical- font-medium-3" (click)="rightMenuOpened = !rightMenuOpened"></i>

            <div class="right-menu" [ngClass]="{colapsed: rightMenuOpened}">
              <div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
                <div class="logo clearfix">
                  <a [routerLink]="['/']" class="logo-text float-left" style="top: 18px; position: relative; left: 18px;">
                    <span class="text align-middle partnerName" style="color: #fdad3a; font-weight: bold;">
                      &lt;!&ndash;{{menuPartnerName}}&ndash;&gt;
                      <img [src]="'assets/'+filePath+'img/logo-menu.svg'" alt="User logo" height="30"/>
                    </span>
                  </a>
                  <a class="nav-close" (click)="rightMenuOpened = false"  style="color: #000;font-size: 20px;">
                    <i class="ft-x"></i>
                  </a>
                </div>
              </div>

            &lt;!&ndash;  <ul class="left-menu-list">
                <li class="left-menu-item" (click)="openCreateModal('player')">
                  <span class="menu-item-icon createUserIcon"><i class="font-medium-3 ft-user-plus"></i></span>
                  <span>Crete Player</span>
                </li>
                <div class="dropdown-divider"></div>
                <li class="left-menu-item" (click)="openCreateModal('agent')">
                  <span class="menu-item-icon createUserIcon"><i class="font-medium-3 ft-user-plus"></i></span>
                  <span>Crete User</span>
                </li>
                <div class="dropdown-divider"></div>
                <li class="left-menu-item" (click)="openTransferModal('player')"  *ngIf="core.checkPermissionsOr(['deposittoplayer', 'playerwithdraw'])">
                  <span class="menu-item-icon transferUserIcon"><i class="font-medium-3 fa fa-exchange"></i></span>
                  <span>Transfer To Player</span>
                </li>
                <div class="dropdown-divider"></div>
                <li class="left-menu-item" (click)="openTransferModal('agent')" *ngIf="core.checkPermissionsOr(['deposittoagent', 'agentwithdraw'])">
                  <span class="menu-item-icon transferUserIcon"><i class="font-medium-3 fa fa-exchange"></i></span>
                  <span>Transfer To User</span>
                </li>
                <div class="dropdown-divider"></div>
                <li class="left-menu-item" (click)="openPartnerUrlsModal()">
                  <span class="menu-item-icon partnerUrlIcon"><i class="font-medium-3 ft-link"></i></span>
                  <span>Partner Urls</span>
                </li>
              </ul>&ndash;&gt;
            </div>
          </li>-->
         <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li>-->
        </ul>
      </div>
    </div>
  </div>





</nav>
