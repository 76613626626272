
<a class="nav-link apptogglefullscreen"  href="javascript:;"

   (click)="openModal(customContent)"><i class="font-medium-3 fa fa-exchange"></i></a>

<ng-template #customContent let-c="close" let-d="dismiss">
  <div *ngIf="activeTransferFormType == transferFormTypes.hidden">
    <div class="modal-header">
      <h4 class="modal-title">{{'Transfer Money' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>

        <ul class="list-unstyled mb-0">
          <li class="d-block mb-2">
            <div class="radio" *ngIf="Core.checkPermissionsOr(['deposittoagent', 'agentwithdraw'])">
              <input type="radio" name="user-type" id="radio1" [(ngModel)]="transferType" value="1">
              <label for="radio1">{{'Agent' | translate}}</label>
            </div>
          </li>
          <li class="d-block mb-2" *ngIf="Core.checkPermissionsOr(['deposittoplayer', 'playerwithdraw'])">
            <div class="radio">
              <input type="radio" name="user-type" id="radio2"  [(ngModel)]="transferType" value="2">
              <label for="radio2">{{'Player' | translate}}</label>
            </div>
          </li>
        </ul>
      </form>

    </div>
    <div class="modal-footer" *ngIf="transferTypes.Player == transferType">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">{{'Close' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="showUserForm(transferFormTypes.showPlayerForm)">{{'Next' | translate}}</button>
    </div>
    <div class="modal-footer" *ngIf="transferTypes.Agent == transferType">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">{{'Close' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="showUserForm(transferFormTypes.showAgentForm)">{{'Next' | translate}}</button>
    </div>
  </div>



  <div *ngIf="activeTransferFormType == transferFormTypes.showAgentForm">
    <app-transfer-agent *ngIf="activeTransferFormType == transferFormTypes.showAgentForm"  [popup]="c" [agentId]="userId"></app-transfer-agent>
  </div>

  <div *ngIf="activeTransferFormType == transferFormTypes.showPlayerForm">
    <app-transfer-player *ngIf="activeTransferFormType == transferFormTypes.showPlayerForm" [popup]="c" [playerId]="userId"></app-transfer-player>
  </div>
</ng-template>
