<div class="modal-header">

  <h4 class="modal-title"> {{'Transfer Money to player' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="popup('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="transferForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">
      <div class="col-sm-12">
        <ul class="list-unstyled">
          <li>{{'My Balance' | translate}}: {{Settings.userData.wallet.balance | currency : Settings.userData.wallet.currency}}</li>
         <!-- <li>{{'My Credit' | translate}}: {{Settings.userData.wallet.credit | currency : Settings.userData.wallet.currency}}</li>-->
        </ul>
      </div>
      <div class="col-sm-12 form-group">
        <label for="Operation">{{'Operation' | translate}}</label>
        <select class="form-control" formControlName="OperationType" id="Operation">
          <option [value]="operationsEnum.Deposit" *ngIf="Core.checkPermissions(['deposittoplayer'])">{{operationsEnum[operationsEnum.Deposit]}}</option>
          <option [value]="operationsEnum.Withdraw" *ngIf="Core.checkPermissions(['playerwithdraw'])">{{operationsEnum[operationsEnum.Withdraw]}}</option>
        </select>
      </div>

      <div class="col-sm-12 form-group" *ngIf="!playerId">
        <label for="player">{{'Players' | translate}}</label>
        <ng-select [items]="playersList"
                   bindLabel="name"
                   id="player"
                   bindValue="id"
                   formControlName="player"
                   (change)="playerChanged($event)"
                   [ngClass]="{ 'is-invalid': submitted && f.player.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.player.invalid || f.player.errors?.required)" class="invalid-tooltip">Required</div>
      </div>
      <div class="col-sm-12 form-group">
        <label for="Amount">{{'Amount' | translate}}</label>
        <input type="number" formControlName="Amount" class="form-control" id="Amount" [ngClass]="{ 'is-invalid': submitted && f.Amount.invalid }" >
        <div *ngIf="submitted && (f.Amount.invalid || f.Amount.errors?.required)" class="invalid-tooltip">Required</div>
      </div>

      <div class="col-sm-12 form-group">
        <label for="MiddleName">{{'Comment For Transfer' | translate}}</label>
        <textarea type="text" formControlName="Comment" class="form-control" id="MiddleName"
                  placeholder="{{'Note For Transfer' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.Comment.invalid }" > </textarea>
        <div *ngIf="submitted && (f.Comment.invalid || f.Comment.errors?.required)" class="invalid-tooltip">Required</div>
      </div>



      <div class="col-sm-12">
        <ul class="list-unstyled">
          <li>{{'Player Balance' | translate}}: {{selectedPlayer.balance | currency: selectedPlayer.currencyId}}</li>
        </ul>
      </div>

    </div>

    <br>
    <br>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary is-invalid" (click)="popup('Cross click')">{{'Close' | translate}}</button>
      <button class="btn btn-primary" type="submit">{{'Submit' | translate}}</button>
    </div>
  </form>
</div>
