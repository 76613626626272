<div class="modal-header">

  <h4 class="modal-title">{{'Player' | translate}} </h4>
  <button type="button" class="close" aria-label="Close" (click)="popup('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="PlayerForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">

      <div class="col-sm-6 form-group">
        <label for="FirstName">{{'First Name' | translate}}</label>
        <input type="text" formControlName="FirstName" class="form-control" id="FirstName">
      </div>

      <div class="col-sm-6 form-group">
        <label for="MiddleName">{{'Middle Name' | translate}}</label>
        <input type="text" formControlName="MiddleName" class="form-control" id="MiddleName">
      </div>

      <div class="col-sm-6 form-group">
        <label for="LastName">{{'Last Name' | translate}}</label>
        <input type="text" formControlName="LastName" class="form-control" id="LastName">
      </div>

      <div class="col-sm-6 form-group">
        <label for="Username">{{'Username' | translate}}</label>
        <input type="text" formControlName="Username" class="form-control" id="Username"
               [ngClass]="{ 'is-invalid': submitted && f.Username.invalid }" required>
        <div *ngIf="submitted && (f.Username.invalid || f.Username.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}
        </div>
      </div>

      <div class="col-sm-6 form-group">
        <label for="Mobile">{{'Mobile' | translate}}</label>
        <input type="text" formControlName="Phone" class="form-control" id="Mobile"
               [ngClass]="{ 'is-invalid': submitted && f.Phone.invalid }">
        <div *ngIf="submitted && (f.Phone.invalid || f.Phone.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
      </div>

      <div class="col-sm-6 form-group">
        <label for="Email">{{'Email' | translate}}</label>
        <input type="text" formControlName="Email" class="form-control" id="Email"
               [ngClass]="{ 'is-invalid': submitted && f.Email.invalid }" required>
        <div *ngIf="submitted && (f.Email.invalid || f.Email.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}</div>
        <div *ngIf="submitted &&  f.Email.errors?.email" class="invalid-tooltip"> <i
          class="ft-alert-circle align-middle"></i> Email must be a valid email address</div>
      </div>

      <div class="col-sm-6 form-group">
        <label for="Password">{{'Currency' | translate}}</label>
        <ng-select [items]="currenciesList"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="CurrencyId"
                   [ngClass]="{ 'is-invalid': submitted && f.CurrencyId.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.CurrencyId.invalid || f.CurrencyId.errors?.required)" class="invalid-tooltip">
          {{'Required' | translate}}
        </div>
      </div>
      <div class="col-sm-6 form-group">
        <label for="Password">{{'Password' | translate}}</label>
        <input type="text" formControlName="Password" class="form-control" id="Password"
               [ngClass]="{ 'is-invalid': submitted && f.Password.invalid }" required>
        <div *ngIf="submitted && (f.Password.invalid || f.Password.errors?.required)" class="invalid-tooltip">{{'Required' | translate}}
        </div>
      </div>
      <div class="col-sm-6 form-group">
        <label for="Password">{{'Country' | translate}}</label>
        <ng-select [items]="countries"
                   bindLabel="Name"
                   bindValue="Id"
                   formControlName="CountryId"
                   [ngClass]="{ 'is-invalid': submitted && f.CountryId.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.CountryId.invalid || f.CountryId.errors?.required)" class="invalid-tooltip">
          {{'Required' | translate}}
        </div>
      </div>
      <div class="col-sm-6 form-group">
        <label for="City">{{'City' | translate}}</label>
        <input type="text" formControlName="City" class="form-control" id="City"
               [ngClass]="{ 'is-invalid': submitted && f.City.invalid }">
      </div>
      <div class="col-sm-6 form-group">
        <label for="BirthDate">{{'BirthDate' | translate}}</label>
        <input type="date" formControlName="BirthDate" class="form-control" id="BirthDate" [ngClass]="{ 'is-invalid': submitted && f.BirthDate.invalid }">
      </div>


      <div class="col-sm-6 form-group">
        <label for="Password">{{'Parent' | translate}}</label>
        <ng-select [items]="agents"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="AgentId"
                   [ngClass]="{ 'is-invalid': submitted && f.AgentId.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.AgentId.invalid || f.AgentId.errors?.required)" class="invalid-tooltip">
          {{'Required' | translate}}
        </div>
      </div>
    </div>

    <br>
    <br>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary is-invalid"  (click)="popup('Cross click')">{{'Close' | translate}}</button>
      <button class="btn btn-primary" type="submit">{{'Submit' | translate}}</button>
    </div>
  </form>
</div>
