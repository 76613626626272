import {Injectable, Inject} from '@angular/core';
import {Observable, Subject} from 'rxjs';

declare var $: any;

export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4
}

export class ChannelConfig {
  url: string | undefined;
  channel: string | undefined;

  constructor() {

  }
}

export class ChannelEvent {
  Name: string | undefined;
  Timestamp: Date;
  Data: any;
  constructor() {
    this.Timestamp = new Date();
  }
}

class ChannelSubject {
  channel: string | undefined;
  subject: Subject<ChannelEvent> | undefined;
}


@Injectable({
  providedIn: 'root',
})
export class ChannelService {

  starting$: Observable<any>;


  connectionState$: Observable<ConnectionState> | undefined;

  error$: Observable<string> | undefined;

  private connectionStateSubject = new Subject<ConnectionState>();
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();
  private HasConnected = false;
  private hubConnection: any;
  public hubProxy: any;
  private subjects = new Array<ChannelSubject>();

  constructor() {
    this.starting$ = this.startingSubject.asObservable();
  }
// pid - partnerId
  init(url: any, hubName: any, pid: any, uid: any, session: any, lang: any) {
    if ($ === undefined || $.hubConnection === undefined) {
      throw new Error(`The variable '$' or the .hubConnection() function are not defined...please check the SignalR scripts have been loaded properly`);
    }


    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();

    this.hubConnection = new $.hubConnection();

    this.hubConnection.qs = {pid, uid, session, lang};
    this.hubConnection.url = url;
    this.hubProxy = this.hubConnection.createHubProxy(hubName);

    /* this.hubConnection.disconnected(() => {
       setTimeout(() => {
         this.start();
       }, 5000); // Restart connection after 5 seconds.
     });*/
    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;

      switch (state.newState) {
        case $.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          console.log('Connecting');
          break;
        case $.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          console.log('connected');
          break;
        case $.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          console.log('Reconnected');
          break;
        case $.signalR.connectionState.disconnected:
          newState = ConnectionState.Disconnected;
          console.log('disconected');
          break;
      }


      this.connectionStateSubject.next(newState);
    });


    this.hubConnection.error((error: any) => {
      this.errorSubject.next(error);
    });

    this.hubProxy.on('newMessage', (e: any) => {
      console.log(e);
    });

    this.hubProxy.on('onEvent', (channel: string, ev: ChannelEvent) => {
      let channelSub = this.subjects.find((x: ChannelSubject) => {
        return x.channel === channel;
      }) as ChannelSubject;

      if (channelSub !== undefined) {
        return channelSub.subject?.next(ev);
      }
    });
  }

  start() {
    this.hubConnection.start({withCredentials: false})
      .done(() => {
        this.HasConnected = true;
        // @ts-ignore
        this.startingSubject.next();
      })
      .fail((error: any) => {
        this.startingSubject.error(error);
      });
  }

  sub(channel: string): Observable<ChannelEvent> {

    let channelSub = this.subjects.find((x: ChannelSubject) => {
      return x.channel === channel;
    }) as ChannelSubject;

    if (channelSub !== undefined) {
      console.log(`Found existing observable for ${channel} channel`);
      // @ts-ignore
      return channelSub.subject.asObservable();
    }

    channelSub = new ChannelSubject();
    channelSub.channel = channel;
    channelSub.subject = new Subject<ChannelEvent>();
    this.subjects.push(channelSub);

    this.starting$.subscribe(() => {
        this.hubProxy.invoke('Subscribe', channel)
          .done(() => {
            console.log(`Successfully subscribed to ${channel} channel`);
          })
          .fail((error: any) => {
            channelSub.subject?.error(error);
          });
      },
      (error: any) => {
        channelSub.subject?.error(error);
      });

    return channelSub.subject.asObservable();
  }


  publish(): void {
    this.hubProxy.invoke.apply(null, arguments);
  }

}
