export const environment = {
  production: true,
  partnerId: '2049',
  skinFilePath: 'partners/2049/',
  PartnerName: 'Gameologic',
  MenuPartnerName: 'Gameologic',
  HasBonus: false,
  version: 10000018,


  serverUrl: 'https://affiliate-api-stg.gameologic.io/',
  SignalRUrl: 'https://affiliate-ws-stg.gameologic.io/',
  bannerJsUrl: 'https://affiliate-cdn.gameologic.io/agent-banner/index.js',
  mediaImgUrl: 'https://affiliate-cdn.gameologic.io/agent-media/image/',
  brandImgUrl: 'https://affiliate-cdn.gameologic.io/agent-brand/2049/',
  socialImgUrl: 'https://affiliate-cdn.gameologic.io/agent-social/2049/',
  paymentImgUrl: 'https://affiliate-cdn.gameologic.io/agent-payment/2049/',
  bonusesImgUrl: 'https://affiliate-cdn.gameologic.io/bonuses/',
  cdnUrl: 'https://affiliate-cdn.gameologic.io/',
};
