import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../../../services/requests.service';
import {SettingsService} from '../../../../services/settings.service';
import {CoreService} from '../../../../services/core.service';
import {AgentBalanceTypeEnum, TransferOperationsEnum} from '../../../../enums/enums.enum';

@Component({
  selector: 'app-transfer-agent',
  templateUrl: './transfer-agent.component.html',
  styleUrls: ['./transfer-agent.component.css']
})
export class TransferAgentComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  @Input() agentId: any;
  public submitted = false;
  public transferForm: any = new FormGroup({
    agent: new FormControl('', [Validators.required]),
    Amount: new FormControl('', [Validators.required]),
    Comment: new FormControl('', [Validators.required]),
    BalanceType: new FormControl('', [Validators.required]),
    OperationType: new FormControl('', [Validators.required])
  });


  public agentBalanceTypeEnum = AgentBalanceTypeEnum;
  public operationsEnum = TransferOperationsEnum;
  public agentsList: Array<any> = [];
  public selectedAgent = {
    balance: 0,
    credit: 0,
    id: 0,
    name: '',
    currency: '',
  };

  constructor(
    public Http: RequestsService,
    public Settings: SettingsService,
    public Core: CoreService
  ) {

    this.Http.httpGet(this.Settings.apiUrls.options.agents)
      .subscribe((res: any) => {
        if (res.data) {
          this.agentsList = res.data;

          setTimeout(() => {
            this.agentsList.forEach((item) => {
              if (item.id == this.agentId) {
                this.selectedAgent = item;
              }
            })
            this.transferForm.patchValue({
              agent: this.agentId ? this.agentId : '',
            })
          }, 500)

        }
      })
  }

  ngOnInit() {
    this.transferForm.patchValue({
      BalanceType: String(AgentBalanceTypeEnum.Real),
      OperationType: this.Core.checkPermissions(['deposittoagent']) ? String(TransferOperationsEnum.Deposit) : String(TransferOperationsEnum.Withdraw)
    })
  }

  get f() {
    return this.transferForm.controls;
  }


  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.transferForm.invalid) {
      return;
    }

    this.Http.httpPost(`${this.Settings.apiUrls.agents.agent}${this.transferForm.value.agent}/transfermoney`, this.transferForm.value, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.Core.showInfoMsg('Success');
          this.popup('Cross click')
        }
      })
  }

  agentChanged(ev: any) {
    if (ev) {
      this.selectedAgent = ev
    } else {
      this.selectedAgent.id = 0;
    }
  }


  ngOnDestroy() {
  }
}
