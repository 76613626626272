import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-list-transfer',
  templateUrl: './list-transfer.component.html',
  styleUrls: ['./list-transfer.component.css'],
})

export class ListTransferComponent implements OnInit {
  @Input() leftList: Array<any> = [];
  @Input() rightList: Array<any> = [];
  @Input() defList: Array<any>  = [];
  @Input() name = '';
  @Output() changed = new EventEmitter<boolean>();

  @Input() hasChange = false;
  /*  public defList = [];*/
  public leftFilter = '';
  public rightFilter = '';

  public listType = {
    left: 1,
    right: 2
  };

  constructor() {
  }

  ngOnInit() {
  }


  selectItem() {
  }

  copyFromTo(from: any) {
    if (from === this.listType.left) {
      const obj = JSON.parse(JSON.stringify(this.leftList));
      obj.reverse();
      obj.forEach((item: any, ind: any) => {
        if (item.Selected) {
          item.Selected = false;
          this.leftList.splice(obj.length - 1 - ind, 1);
          this.rightList.push(item);
        }
      });
    }
    if (from === this.listType.right) {
      const obj = JSON.parse(JSON.stringify(this.rightList));
      obj.reverse();
      obj.forEach((item: any, ind: any) => {
        if (item.Selected) {
          item.Selected = false;
          this.rightList.splice(obj.length - 1 - ind, 1);
          this.leftList.push(item);
        }
      });
    }

    this.checkChanges();
  }

  leftToRight(item: any, ind: any) {
    this.rightList.push(item);
    this.leftList.splice(ind, 1);
    this.checkChanges();
  }

  rightToLeft(item: any, ind: any) {
    this.leftList.push(item);
    this.rightList.splice(ind, 1);
    this.checkChanges();
  }


  checkUnChkAll(listType: any, type: any) {
    if (this.listType.left === listType) {
      this.leftList.forEach((item, ind) => {
        item.Selected = type
      })
    }
    if (this.listType.right === listType) {
      this.rightList.forEach((item, ind) => {
        item.Selected = type
      })
    }
  }


  checkChanges() {
    if (!this.hasChange) {
      this.hasChange = true;
      this.changed.emit(true);
    }
  }
}
