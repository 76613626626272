<a class="nav-link apptogglefullscreen" href="javascript:;"
   (click)="openModal(customContent)"><i class="font-medium-3 ft-link"></i></a>

<ng-template #customContent let-c="close" let-d="dismiss">
  <div >
    <div class="modal-header" >
      <h4 class="modal-title" ><i class="font-medium-3 ft-link"></i>{{'Partner Urls' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>

        <div class="row align-items-center">

          <div class="col-sm-12 flex align-items-center">
            <fieldset class="mr-2" style="    width: calc(100% - 155px);">
              <label for="partnerLink">Partner Link</label>
              <div class="input-group">
                <input type="text" placeholder="partnerLink" class="form-control" [value]="partnerLink" [attr.disabled]="true">
                <textarea [value]="partnerLink" class="mybtag" id="partnerLink" style="display: none"></textarea>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" (click)="copyText('partnerLink')">Copy</button>
                </div>
              </div>
            </fieldset>

            <div class="float-right flex">
              <label class="mr-1">Short</label>
              <div class="custom-switch">
                <input class="custom-control-input" id="right-s-switch-partnerUrl" type="checkbox"
                       [checked]="showShortPartnerUrl" [(ngModel)]="showShortPartnerUrl"
                       (ngModelChange)="showSortUrl('partner')">
                <label class="custom-control-label" for="right-s-switch-partnerUrl"></label>
              </div>
            </div>
          </div>

          <div class="col-sm-12 flex align-items-center">
            <fieldset class="mr-2" style="    width: calc(100% - 155px);">
              <label for="referralLink">Referral Link</label>
              <div class="input-group">
                <input type="text" placeholder="Refferal" class="form-control" [value]="referralLink" [attr.disabled]="true">
                <textarea [value]="referralLink" class="mybtag" id="referralLink" style="display: none"></textarea>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" (click)="copyText('referralLink')">Copy</button>
                </div>
              </div>
            </fieldset>

            <div class="float-right flex">
              <label class="mr-1">Short</label>
              <div class="custom-switch">
                <input class="custom-control-input" id="right-s-switch-showShortReferralUrl" type="checkbox"
                       [checked]="showShortReferralUrl" [(ngModel)]="showShortReferralUrl"
                       (ngModelChange)="showSortUrl('referral')">
                <label class="custom-control-label" for="right-s-switch-showShortReferralUrl"></label>
              </div>
            </div>
          </div>


        </div>
      </form>

    </div>
    <div class="modal-footer" >
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">Close</button>
    </div>
  </div>

</ng-template>
