import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../../services/settings.service';
import {CoreService} from '../../../../services/core.service';
import {RequestsService} from '../../../../services/requests.service';
import {AgentCommissionPlanTypes, AgentCommissionPlanTypesList} from '../../../../enums/enums.enum';
import {PlanModel} from '../../../../models/models';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.css']
})
export class CreateAgentComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  public AgentForm : any = new FormGroup({
    FirstName: new FormControl(''),
    SecondName: new FormControl(''),
    LastName: new FormControl(''),
    SecondLastName: new FormControl(''),
    Username: new FormControl('', [Validators.required]),
    Mobile: new FormControl(null),
    Email: new FormControl('', [Validators.required]),
    CountryId: new FormControl('', [Validators.required]),
    Password: new FormControl('', [Validators.required]),
    Role: new FormControl('', [Validators.required]),
    Parent: new FormControl('', [Validators.required]),
    Currency: new FormControl('', [Validators.required]),
  });

  public PlansForm: any = new FormGroup({
    type: new FormControl(AgentCommissionPlanTypes.Revenue),
    planId: new FormControl('', [Validators.required]),
  });
  public roleList: Array<any> = [];
  public currenciesList: Array<any> = [];
  public countriesList: Array<any> = [];
  public agentsList: Array<any> = [];
  public activeStep = 1;
  public planTypesList = AgentCommissionPlanTypesList;
  public planTypesEnum = AgentCommissionPlanTypes;
  public plansList: Array<any> = [];
  public plan: PlanModel = {};
  public submitted = false;
  public partnerId = Number(environment.partnerId);

  constructor(
    public Settings: SettingsService,
    public Core: CoreService,
    public Http: RequestsService
  ) {
    if (this.partnerId === 16) {
      this.AgentForm.patchValue({Currency: 'LBP'})
    }


    this.Http.httpGet(this.Settings.apiUrls.options.Roles)
      .subscribe((res: any) => {
        if (res.data) {
          this.roleList = res.data;
        }
      });

    this.Http.httpGet(this.Settings.apiUrls.options.partnerCurrencies)
      .subscribe((res: any) => {
        if (res.data) {
          this.currenciesList = res.data;
        }
      });
    this.Http.httpGet(`${this.Settings.apiUrls.options.countries}?languageId=en`)
      .subscribe((res: any) => {
        if (res.data) {
          this.countriesList = res.data;
        }
      });
    this.planTypeChanged(AgentCommissionPlanTypes.Revenue);
  }

  ngOnInit() {
    console.log(this.popup)
  }

  get f() {
    return this.AgentForm.controls;
  }

  get plansF() {
    return this.PlansForm.controls;
  }

  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.AgentForm.invalid) {
      return;
    }
  }

  changeStep(stepId: any) {
    this.submitted = true;
    if (this.AgentForm.invalid) {
      return;
    }
    this.activeStep = stepId;
  }

  planTypeChanged(val: any) {
    this.PlansForm.patchValue({planId: ''});
    this.plan = new PlanModel();
    this.plansList.length = 0;
    this.Http.httpGet(`${this.Settings.apiUrls.options.plans}?type=${val}`)
      .subscribe((res: any) => {
        if (res.data) {
          this.plansList = res.data;
          if (this.plansList.length) {
            this.PlansForm.patchValue({planId: this.plansList[0].id});
            this.planIdChanged(this.plansList[0].id);
          }
        }
      })

  }
  planIdChangedTarget(target: any){
    this.planIdChanged(target.value);
  }
  planIdChanged(planId: any) {
    this.Http.httpGet(`${this.Settings.apiUrls.plan.getPlansById}/${planId}`)
      .subscribe((res: any) => {
        if (res.data) {
          this.plan = res.data;
          if (this.plan?.product) {
            this.plan.ranges = [];
            this.plan.product.rages.forEach((rItem: any, rInd: any) => {
              if (rItem.max === null) {
                rItem.max = '∞';
              }

              this.plan?.ranges.push(rItem);
            })
          }
        }
      })
  }

  ngOnDestroy() {
  }

  roleChanged(ev: any) {
    if (ev.id) {
      this.Http.httpGet(`${this.Settings.apiUrls.options.roleAgents}?roleId=${ev.id}`).subscribe((res: any) => {
        if (res.data) {
          this.agentsList = res.data;
        }
      })
    }
  }

  createAgent() {
    var kkk: any = new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      SecondName: new FormControl(''),
      LastName: new FormControl('', [Validators.required]),
      SecondLastName: new FormControl('', [Validators.required]),
      Username: new FormControl('', [Validators.required]),
      CurrencyId: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required]),
      Role: new FormControl('', [Validators.required]),
      Parent: new FormControl('', [Validators.required]),
      Currency: new FormControl('', [Validators.required]),
    });
    const agentObj = {
      'planId': this.PlansForm.value.planId,
      'name': this.AgentForm.value.FirstName,
      'secname': this.AgentForm.value.SecondName,
      'surname': this.AgentForm.value.LastName,
      'username': this.AgentForm.value.Username,
      'email': this.AgentForm.value.Email,
      'mob': this.AgentForm.value.Mobile || null,
      'country': this.AgentForm.value.CountryId,
      /* 'country': 0,
       'city': 'string',
       'address': 'string',
       'zip': 'string',
       'ime': 'string',*/
      'parentId': this.AgentForm.value.Parent,
      // 'parentagent': this.AgentForm.value.Parent,

      'currency': this.AgentForm.value.Currency,
      'password': this.AgentForm.value.Password,
      'roleid': this.AgentForm.value.Role,
    };

    this.Http.httpPost(this.Settings.apiUrls.agents.addAgents, agentObj, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.popup('Cross click');
          this.Core.showInfoMsg('Agent Created');
        }
      })
  }
}
