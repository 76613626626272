import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AgentCommissionPlanTypes, AgentCommissionPlanTypesList, CommissionPlanTypes} from '../../../enums/enums.enum';
import {PlanModel} from '../../../models/models';
import {SettingsService} from '../../../services/settings.service';
import {CoreService} from '../../../services/core.service';
import {RequestsService} from '../../../services/requests.service';

@Component({
  selector: 'app-set-permission',
  templateUrl: './set-permission.component.html',
  styleUrls: ['./set-permission.component.css']
})

export class SetPermissionComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  @Input() agentId: any;
  @Output() planChanged = new EventEmitter<any>();
  public submitted = false;
  public planTypesList = AgentCommissionPlanTypesList;
  public planTypesEnum = CommissionPlanTypes;
  public plansList: Array<any> = [];
  public plan: PlanModel | undefined;
  public PlansForm: any = new FormGroup({
    type: new FormControl('', [Validators.required]),
    planId: new FormControl('', [Validators.required]),
  });

  constructor(
    public Settings: SettingsService,
    public Core: CoreService,
    public Http: RequestsService) {
  }

  ngOnInit() {
  }

  get plansF() {
    return this.PlansForm.controls;
  }

  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.PlansForm.invalid) {
      return;
    }
  }
  planTypeChangedTarget(target: any){
    this.planTypeChanged(target.value)
  }
  planTypeChanged(val: any) {
    this.PlansForm.patchValue({planId: ''});
    this.plan = undefined;
    this.plansList.length = 0;
    this.Http.httpGet(`${this.Settings.apiUrls.options.plans}?type=${val}`, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.plansList = res.data;
          if (this.plansList.length) {
            this.PlansForm.patchValue({planId: this.plansList[0].id});
            this.planIdChanged(this.plansList[0].id);
          }
        }
      })

  }
  planIdChangedTarget(target: any){
    this.planIdChanged(target.value);
  }
  planIdChanged(planId: any) {
    this.planChanged.emit({planId, type: this.PlansForm.value.type});
    this.Http.httpGet(`${this.Settings.apiUrls.plan.getPlansById}/${planId}`, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.plan = res.data;
          if (this.plan?.product) {
            this.plan.ranges = [];
            this.plan.product.rages.forEach((rItem: any, rInd: any) => {
              if (rItem.max === null) {
                rItem.max = '∞';
              }

              this.plan?.ranges.push(rItem);
            })
          }

          if (this.plan?.revenuePlan.product) {
            this.plan.revenuePlan.ranges = []
            this.plan.revenuePlan.product.rages.forEach((rItem: any, rInd: any) => {
              if (rItem.max === null) {
                rItem.max = '∞';
              }

              this.plan?.revenuePlan.ranges.push(rItem);
            })
          }
          if (this.plan?.cpaPlan.product) {
            this.plan.cpaPlan.ranges = []
            this.plan.cpaPlan.product.rages.forEach((rItem: any, rInd: any) => {
              if (rItem.max === null) {
                rItem.max = '∞';
              }

              this.plan?.cpaPlan.ranges.push(rItem);
            })
          }


        }
      })
  }


  ngOnDestroy() {
  }
}
