import {RequestsService} from '../requests.service';
import {SettingsService} from '../settings.service';

import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthService {

  constructor(
    public Http: RequestsService,
    public Settings: SettingsService,
    public router: Router
  ) {


    if (localStorage.getItem('uid')) {
      this.Http.httpGet(this.Settings.apiUrls.agents.agent + localStorage.getItem('uid'))
        .subscribe((res: any) => {
          if (!res.hasError) {
            localStorage.setItem('user', JSON.stringify(res.data));
            this.Settings.userData = res.data;
            localStorage.setItem('uid', res.data.id);
          }
        })
    }

  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    return this.Http.httpPost(this.Settings.apiUrls.login, {
      Username: email,
      Password: password
    });
  }

  logout() {

    this.Http.httpPost(this.Settings.apiUrls.logout, {})
      .subscribe((res: any) => {
        if (res && res.data) {

        }
        localStorage.clear();
        setTimeout(() => {
          location.reload();
        }, 200)
      });

  }

  isAuthenticated() {
    if (localStorage.getItem('session')) {
      return true;
    } else {
      return false;
    }

  }
}
