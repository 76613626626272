import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ChannelService} from './signalr/signalr.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  public showPreview = false;
  public serverUrl = environment.serverUrl;
  public partnerId = environment.partnerId;
  public SignalRUrl = environment.SignalRUrl;
  public MediaImgUrl = environment.mediaImgUrl;
  public socialImgUrl = environment.socialImgUrl;
  public activeLangId = 'en';

  public openCreateUserModal: EventEmitter<any> = new EventEmitter<any>();
  public openPartnerUrlsModal: EventEmitter<any> = new EventEmitter<any>();
  public userData: any;
  public userId: any;
  public apiUrls = {
    login: `${this.serverUrl}api/account/login`,
    logout: `${this.serverUrl}api/account/logout`,

    newsletter: {
      create: `${this.serverUrl}api/newsletter/create`,
    },
    account: {
      register: `${this.serverUrl}api/account/register`,
      confirmEmail: `${this.serverUrl}api/account/confirmemail`,
      countries: `${this.serverUrl}api/account/countries`,
      currencies: `${this.serverUrl}api/account/currencies`,
      languages: `${this.serverUrl}api/account/languages`,
    },
    settings: {

      agent: `${this.serverUrl}api/settings/agent/`,
      roles: `${this.serverUrl}api/settings/roles`,
      addRoles: `${this.serverUrl}api/settings/role/add`,
      updateRoles: `${this.serverUrl}api/settings/roles/update`,
      setDefaultRole: `${this.serverUrl}api/settings/role/setdefault`,
      newRolePermissions: `${this.serverUrl}api/settings/new/role/permissions`,
      role: `${this.serverUrl}api/settings/role/`,
      ownPermissions: `${this.serverUrl}api/settings/own/permissions/`,



      plans: `${this.serverUrl}api/settings/plans`,
      plan: `${this.serverUrl}api/settings/plans/`,
      updateUi: `${this.serverUrl}api/settings/ui/update`,
      addPlans: `${this.serverUrl}api/settings/plans/add`,


      getGridColumns: `${this.serverUrl}api/settings/getgridcolumns?gridName=`,
      updateGrid: `${this.serverUrl}api/settings/updategrid`,
    },
    player: {
      addPlayer: `${this.serverUrl}api/players/add`,
      player: `${this.serverUrl}api/player/`,
      filter: `${this.serverUrl}api/players/filter`,
    },
    plan: {
      getPlansByType: `${this.serverUrl}api/plans/type/`,
      getPlansById: `${this.serverUrl}api/plans/`,
      add: `${this.serverUrl}api/plans/add`,
    },
    agents: {
      addAgents: `${this.serverUrl}api/agents/add`,
      agents: `${this.serverUrl}api/agents/`,
      filter: `${this.serverUrl}api/agents/filter`,
      deletedFilter: `${this.serverUrl}api/agents/deleted/filter`,
      pendingFilter: `${this.serverUrl}api/agents/pending/filter`,
      tree: `${this.serverUrl}api/agents/tree`,
      agent: `${this.serverUrl}api/agent/`,
      agentGroup: `${this.serverUrl}api/agent/group/`,
      globalFind: `${this.serverUrl}api/agents/globalfind`,
      userGroups: `${this.serverUrl}api/groups`,
      getGroupById: `${this.serverUrl}api/group/`,
      addUserGroups: `${this.serverUrl}api/groups/add`,
    },
    options: {
      agents: `${this.serverUrl}api/options/agents`,
      partnerproducts: `${this.serverUrl}api/options/products`,
      players: `${this.serverUrl}api/options/players`,
      directPlayers: `${this.serverUrl}api/options/players-direct`,
      plans: `${this.serverUrl}api/options/plans`,
      countries: `${this.serverUrl}api/options/countries`,
      currencies: `${this.serverUrl}api/options/currencies`,
      partnerCurrencies: `${this.serverUrl}api/options/currencies`,
      partnerPayments: `${this.serverUrl}api/options/payment-methods`,
      Roles: `${this.serverUrl}api/options/roles`,
      emailTemplateTypes: `${this.serverUrl}api/options/template/types`,
      emailTemplateVariables: `${this.serverUrl}api/options/template/keys`,
      roleAgents: `${this.serverUrl}api/options/roleagents`,
      domains: `${this.serverUrl}api/options/domains`,
      agentGroups: `${this.serverUrl}api/options/agentgroups`,

      sourceLinks: `${this.serverUrl}api/options/source-links`,
      targetLinks: `${this.serverUrl}api/options/target-links`,
      bonuses: `${this.serverUrl}api/options/bonuses`,
      imageBanners: `${this.serverUrl}api/options/image-banners`,
      videoBanners: `${this.serverUrl}api/options/video-banners`,
      socials: `${this.serverUrl}api/options/socials`,
      newsletterKeys: `${this.serverUrl}api/options/newsletter/keys`,
    },
    repots: {
      byAgentsTransaction: `${this.serverUrl}api/reports/byagentstransaction`,
      exportAgentsTransaction: `${this.serverUrl}api/reports/byagentstransaction/export`,
      byPlayersTransaction: `${this.serverUrl}api/reports/byplayerstransaction`,
      exportPlayersTransaction: `${this.serverUrl}api/reports/byplayerstransaction/export`,
      byAgentPlayer: `${this.serverUrl}api/reports/byagentplayer`,
      exportByAgentPlayer: `${this.serverUrl}api/reports/byagentplayer/export`,
      byProduct: `${this.serverUrl}api/reports/ByProduct`,
      exportByProduct: `${this.serverUrl}api/reports/byProduct/export`,
      byAgent: `${this.serverUrl}api/reports/byagent`,
      exportByAgent: `${this.serverUrl}api/reports/byagent/export`,
      byAgentDaily: `${this.serverUrl}api/reports/byagentdaily`,
      exportByAgentDaily: `${this.serverUrl}api/reports/byagentdaily/export`,
    },
    dashboard: {
      todayPlayers: `${this.serverUrl}api/dashboard/cards/todayplayers`,
      currentEarning: `${this.serverUrl}api/dashboard/currentEarning`,
      todayAgents: `${this.serverUrl}api/dashboard/cards/todayagents`,
      allPlayers: `${this.serverUrl}api/dashboard/cards/allplayers`,
      allAgents: `${this.serverUrl}api/dashboard/cards/allagents`,
      openBets: `${this.serverUrl}api/dashboard/cards/openbets`,
      totals: `${this.serverUrl}api/dashboard/totals`,
      topPlayers: `${this.serverUrl}api/dashboard/topplayers`,
      topAgents: `${this.serverUrl}api/dashboard/topAgents`,
      profitableProducts: `${this.serverUrl}api/dashboard/profitableProducts`,
      dailyProfit: `${this.serverUrl}api/dashboard/dailyProfit`,

    },
    media: {
      addImage: `${this.serverUrl}api/media/image/add`,
      filterImage: `${this.serverUrl}api/media/image/filter`,
      deleteImage: `${this.serverUrl}api/media/image/delete`,
      updateImage: `${this.serverUrl}api/media/image/update`,
      addVideo: `${this.serverUrl}api/media/video/add`,
      getVideos: `${this.serverUrl}api/media/videos`,
      getImages: `${this.serverUrl}api/media/image`,
      filterVideo: `${this.serverUrl}api/media/video/filter`,
      deleteVideo: `${this.serverUrl}api/media/video/delete`,
      updateVideo: `${this.serverUrl}api/media/video/update`,
      addVideoBanner: `${this.serverUrl}api/media/video/banner/add`,
      filterVideoBanner: `${this.serverUrl}api/media/video/banner/filter`,
      deleteVideoBanner: `${this.serverUrl}api/media/video/banner/delete`,
      updateVideoBanner: `${this.serverUrl}api/media/video/banner/update`,
      addImageBanner: `${this.serverUrl}api/media/image/banner/add`,
      filterImageBanner: `${this.serverUrl}api/media/image/banner/filter`,
      deleteImageBanner: `${this.serverUrl}api/media/image/banner/delete`,
      updateImageBanner: `${this.serverUrl}api/media/image/banner/update`,
      createdLinks: `${this.serverUrl}api/media/createdLinks`,
      addLink: `${this.serverUrl}api/media/link/add`,
      filterLink: `${this.serverUrl}api/media/link/filter`,
      filterLink1: `${this.serverUrl}api/media/link/filter1`,
      deleteLink: `${this.serverUrl}api/media/link/delete`,
      updateLink: `${this.serverUrl}api/media/link/update`,
    },
    configs: {
      regFieldsUpdate: `${this.serverUrl}api/configs/reg-fields-update`,
      getEmailsTemplates: `${this.serverUrl}api/configs/skin/${environment.partnerId}/emailTemplates`,
      getEmailsTemplate: `${this.serverUrl}api/configs/skin/${environment.partnerId}/emailTemplate`,
      createEmailsTemplates: `${this.serverUrl}api/configs/skin/${environment.partnerId}/emailTemplate/create`,
      regFields: `${this.serverUrl}api/configs/reg-fields`,
      uploadLogo: `${this.serverUrl}api/configs/brand/logo`,
      uploadBackground: `${this.serverUrl}api/configs/brand/background`,
      uploadFavicon: `${this.serverUrl}api/configs/brand/favicon`,
      uploadHelp: `${this.serverUrl}api/configs/brand/help`,
      uploadFaq: `${this.serverUrl}api/configs/brand/faq`,
      uploadAboutUs: `${this.serverUrl}api/configs/brand/aboutus`,
      uploadTestimonals: `${this.serverUrl}api/configs/brand/testimonals`,
      brandSettings: `${this.serverUrl}api/configs/brand/settings`,
      updateBrandSettings: `${this.serverUrl}api/configs/brand/settings-update`,
      getBrandCost: `${this.serverUrl}api/configs/brand/costs`,
      updateBrandCost: `${this.serverUrl}api/configs/brand/costs-update`,

      uploadPaymentIcon: `${this.serverUrl}api/configs/payments/icon`,
      paymentMethods: `${this.serverUrl}api/configs/skin/${environment.partnerId}/paymentmethods`,
      updatePaymentState: `${this.serverUrl}api/configs/skin/${environment.partnerId}/paymentmethods/update-state`,
      updatePaymentLimits: `${this.serverUrl}api/configs/skin/${environment.partnerId}/paymentmethods/update-limits`,
      updatePaymentInfo: `${this.serverUrl}api/configs/skin/${environment.partnerId}/paymentmethods/update-info`,
      createPayment: `${this.serverUrl}api/configs/skin/${environment.partnerId}/paymentmethods/create`,

      products: `${this.serverUrl}api/configs/missing/products`,
      partnerProducts: `${this.serverUrl}api/configs/partnerproducts`,
      updatePartnerProducts: `${this.serverUrl}api/configs/partnerproducts/update`,
      currencies: `${this.serverUrl}api/configs/currencies`,
      partnerCurrencies: `${this.serverUrl}api/configs/partnercurrencies`,
      updatePartnerCurrencies: `${this.serverUrl}api/configs/partnercurrencies/update`,
      foraddCurrencies: `${this.serverUrl}api/configs/missing/currencies`,
      languages: `${this.serverUrl}api/configs/languages`,
      foraddLanguages: `${this.serverUrl}api/configs/missing/languages`,
      partnerLanguages: `${this.serverUrl}api/configs/partnerlanguages`,
      updatePartnerLanguages: `${this.serverUrl}api/configs/partnerlanguages/update`,

      socials: `${this.serverUrl}api/skin/${environment.partnerId}/socials`,
      addSocial: `${this.serverUrl}api/skin/${environment.partnerId}/socials/add`,
      social: `${this.serverUrl}api/skin/${environment.partnerId}/socials/social/`,

      createAdditionalWebsite: `${this.serverUrl}api/configs/additionalwebsite/create`,
      updateAdditionalWebsite: `${this.serverUrl}api/configs/additionalwebsite/update`,
      deleteAdditionalWebsite: `${this.serverUrl}api/configs/additionalwebsite/delete`,
      additionalWebsites: `${this.serverUrl}api/configs/additionalwebsites`,
      setAgentsDomain: `${this.serverUrl}api/configs/agents/domain`,
      setGroupsDomain: `${this.serverUrl}api/configs/groups/domain`,


      getContentByLangId: `${this.serverUrl}api/skin/${environment.partnerId}/contents/language/`,
      contents: `${this.serverUrl}api/skin/${environment.partnerId}/contents/`,
    },
    withdrawals: {
      withdrawals: `${this.serverUrl}api/withdrawals`,
      mnyWithdrawals: `${this.serverUrl}api/withdrawals/own`,
      createWithdraw: `${this.serverUrl}api/withdrawals/create`,
      changeState: `${this.serverUrl}api/withdrawals/change/state`,
      cancel: `${this.serverUrl}api/withdrawals/cancel/own`,
      withdrawal: `${this.serverUrl}api/withdrawal/`,
      total: `${this.serverUrl}api/withdrawal/totals`,
    },
    bonuses: {
      getBonuses: `${this.serverUrl}api/bonuses?search=`,
      bonuses: `${this.serverUrl}api/bonuses`,
      addBonuses: `${this.serverUrl}api/bonuses/add`,
    },
    campaigns: {
      campaigns: `${this.serverUrl}api/campaigns?search=`,
      campaign: `${this.serverUrl}api/campaigns/`,
      createCampaign: `${this.serverUrl}api/campaigns/create`,
      qrCampaign: `${this.serverUrl}api/campaigns/qr?`,
    }
  };

  public SignalrHasConnected = false;

  public signalREvent = new Observable((observer) => {
    if (this.sigCon && this.sigCon.HasConnected) {
      observer.next(true);
      observer.complete();
    }
    else {
      this.sigCon.starting$.subscribe((e: any) => {
        this.SignalrHasConnected = true;
        observer.next(this);
        observer.complete();
      });
    }

  });

  public sigCon: any;

  constructor() {
    if (localStorage.getItem('user')) {
      if (localStorage.getItem('user') === 'undefined') {
        localStorage.clear();
        location.reload();
        return;
      }
      // @ts-ignore
      this.userData = JSON.parse(localStorage.getItem('user'));
      this.userId = this.userData.id;
    }
    this.sigCon = new ChannelService();

  }
}
