import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { CommissionPlanTypes} from '../../enums/enums.enum';
import {SettingsService} from '../../services/settings.service';
import {RequestsService} from '../../services/requests.service';
import {CoreService} from '../../services/core.service';

@Component({
  selector: 'app-commission-card-ref',
  templateUrl: './commission-card-referral.component.html',
  styleUrls: ['./commission-card-referral.component.css']
})

export class CommissionCardReferralComponent implements OnInit, OnDestroy {
  @Input() isEditable = false;
  @Input() hideTopIcons = false;
  @Input() showHide = false;
  @Input() dataItem: any;
  hiddenContent = false;
  @Output() changed = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<number>();
  public PlanTypes: any = CommissionPlanTypes;

  constructor(
    public settings: SettingsService,
    public core: CoreService,
    public http: RequestsService
  ) {
  }

  ngOnInit() {
    if (this.showHide) {
      this.hiddenContent = true;
    }
  }

  addNewRow() {
    const rLength = this.dataItem.ranges.length;
    const firstItem = this.dataItem.ranges[rLength - 1];
    this.dataItem.ranges.push({min: firstItem.min + 1, percent: firstItem.percent + 1})

    this.checkChanges()
  }

  deleteRow(ind: any) {
    this.dataItem.ranges.splice(ind, 1);

    if (ind === 0) {
      this.dataItem.ranges[0].min = 0;
    } else {
      this.dataItem.ranges[ind].min = this.dataItem.ranges[ind - 1].min
    }

    this.checkChanges()
  }


  fromChanged(ind: any, target: any) {
    const val: any = target.value;
    if (ind !== 0) {
      this.dataItem.ranges[ind - 1].max = val;
      this.checkChanges()
    }
  }


  toChanged(ind: any, val: any) {
    if (ind < this.dataItem.ranges.length - 1) {
      this.dataItem.ranges[ind + 1].min = val;
      this.checkChanges()
    }
  }

  percentChange(ind: any, val: any) {
    if (ind < this.dataItem.ranges.length - 1) {
      this.dataItem.ranges[ind + 1].percent = val;
      this.checkChanges()
    }
  }

  checkChanges() {
    this.changed.emit(true);
  }

  deletePlan(id: any) {
    this.delete.emit(id);
  }

  ngOnDestroy() {
  }
  changeIsDefoult(item: any) {
    this.http.httpPost(this.settings.apiUrls.settings.plan + item.id + '/makeDefault', {})
      .subscribe((res: any) => {
        if (res.data) {
          location.reload()
        } else {
          item.default = false;
        }
      });
  }

  setDefault(item: any) {
    item.default = true;
    this.http.httpPost(this.settings.apiUrls.plan.getPlansById + item.id + '/update', item, true)
      .subscribe((res: any) => {
        if (res.data) {
          location.reload();
          this.core.showInfoMsg(`Plan ${item.Name} was performed by default`)
        } else {
          item.default = false;
        }
      });
  }
}
