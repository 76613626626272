import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
 import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-regform-steps',
  templateUrl: './regform-steps.component.html',
  styleUrls: ['./regform-steps.component.css'],
})

export class RegformStepsComponent implements OnInit, OnDestroy {


  objectKeys = Object.keys;
  @Input() leftList: Array<any> | undefined;
  @Input() name = '';
  @Output() changed = new EventEmitter<boolean>();

  @Input() hasChange = false;
  /* public defList = []; */
  public leftFilter = '';
  public rightFilter = '';

  public listType = {
    left: 1,
    right: 2
  };
  public steps: any = {
    1: [],
    2: [],
  }

  MANY_ITEMS = 'MANY_ITEMS';
  BAG = 'DRAGULA_EVENTS';
  subs = new Subscription();
  subsNgFor = new Subscription();

  constructor(private dragulaService: DragulaService) {

    // @ts-ignore
    this.subs.add(dragulaService.drag(this.BAG).subscribe(({el}) => {
        this.removeClass(el, 'ex-moved');
      })
    );
    // @ts-ignore
    this.subs.add(dragulaService.drop(this.BAG).subscribe(({el}) => {
        this.addClass(el, 'ex-moved');
      })
    );
    // @ts-ignore
    this.subs.add(dragulaService.over(this.BAG).subscribe(({el: any, container}) => {
        this.addClass(container, 'ex-over');
      })
    );
    // @ts-ignore
    this.subs.add(dragulaService.out(this.BAG).subscribe(({el, container}) => {
        this.removeClass(container, 'ex-over');
      })
    );
    // @ts-ignore
    this.subs.add(dragulaService.dropModel('COLUMNS').subscribe(({ target, item}) => {
   /*     console.log(name)
        console.log(el)
        console.log(target)
        console.log(source)
        console.log(sibling)
        console.log(sourceModel)
        console.log(targetModel)
        console.log(item)*/

        item.stepNumber = target.id
        // item.itemGroup = target.id;

      })
    );
  }

  ngOnInit() {
    this.generateSteps(this.leftList)
  }

  generateSteps(list: any) {
    list = JSON.parse(JSON.stringify(this.leftList))
    list.forEach((item: any) => {
      if (!this.steps[item.stepNumber]) {
        this.steps[item.stepNumber] = [item]
      } else {
        this.steps[item.stepNumber].push(item)
      }
    })
  }


  selectItem() {
  }

  copyFromTo(from: any, to: any) {
    const obj = JSON.parse(JSON.stringify(this.steps[from]));
    obj.reverse();
    obj.forEach((item: any, ind: any) => {
      if (item.Selected) {
        item.Selected = false;
        item.stepNumber = to;
        this.steps[from].splice(obj.length - 1 - ind, 1);
        this.steps[to].push(item);
      }
    });
    this.checkChanges();
  }




  checkChanges() {
    if (!this.hasChange) {
      this.hasChange = true;
      this.changed.emit(true);
    }
  }

  private hasClass(el: Element, name: string): any {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: Element, name: string): void {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: Element, name: string): void {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }


  ngOnDestroy() {

    this.dragulaService.destroy('COLUMNS');
    this.subs.unsubscribe();
    this.subsNgFor.unsubscribe();
  }

  removeStep(stepId: any) {
    this.steps[stepId].forEach((item: any) => {
      item.stepNumber = 1;
      this.steps['1'].push(JSON.parse(JSON.stringify(item)));
    })
    // this.steps[1] = [...this.steps[1], ...this.steps[stepId]]
    delete this.steps[stepId]
  }

  addStep() {
    this.steps[Object.keys(this.steps).length + 1] = [];
  }

  reset() {
    this.steps = {
      1: [],
      2: [],
    }
    this.generateSteps(this.leftList)
  }

  save() {
    const formItems: Array<any> = [];
    let order = 1;

    for (const step in this.steps) {
      this.steps[step].forEach((item: any) => {
        item.order = order;
        formItems.push(item)
        order++;
      })
    }

    return formItems;

  }
}
