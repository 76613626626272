import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {SettingsService} from './settings.service';
import {environment} from '../../../environments/environment';
import {CoreService} from './core.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ErrorList} from '../enums/errorList.enum';


@Injectable({
  providedIn: 'root',
})

export class RequestsService {

  private connectionMonitor: Observable<boolean>;
  private errorsEnum: any = ErrorList

  constructor(
    private http: HttpClient,
    private core: CoreService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private settings: SettingsService,
    // private _note: NoteficationService
  ) {
    this.connectionMonitor = new Observable((observer) => {
      window.addEventListener('offline', () => {
        observer.next(false);
      });
      window.addEventListener('online', () => {
        setTimeout(() => {
          location.reload();
        }, 2 * 1000);

        observer.next(true);
      });
    });
    this.monitor().subscribe();
  }

  httpGet(url: any, showSpinner?: any) {
    if (showSpinner) {
      this.core.showSpinner()
    }
    // console.log(1)
    return this.http.get(url, this.userActiveHeaders())
      .pipe(
        map((resp: any) => {
            if (showSpinner) {
              this.core.hideSpinner()
            }
            if (resp['hasError']) {
              this.translate.get('error_' + this.errorsEnum[resp['errorId']]).subscribe((res: string) => {
                this.toastr.error(res, 'error !!!', {
                  positionClass: 'toast-top-right'
                });
              });
            }


            return resp;
          }
        ),

        catchError((err: HttpErrorResponse) => {
          return this.handleError(err)
        }));


    /*   .pipe(

       catchError(this.handleError)
     );*/

  }

  httpPost(url: any, body: any, showSpinner?: any) {
    if (showSpinner) {
      this.core.showSpinner()
    }
    return this.http.post(url, body, this.userActiveHeaders())
      .pipe(map((resp: any) => {
            if (showSpinner) {
              this.core.hideSpinner()
            }
            if (resp['hasError']) {
              this.translate.get('error_' + this.errorsEnum[resp['errorId']]).subscribe((res: string) => {
                this.toastr.error(res, 'error !!!', {
                  positionClass: 'toast-top-right'
                });
              });
            }
            return resp;
          }
        ),

        catchError((err: HttpErrorResponse) => {
          return this.handleError(err)
        }));
  }


  private handleError(error: HttpErrorResponse) {
    if (error) {
      this.core.hideSpinner()
    }

    if (error.status === 401) {
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 500);


    }


    if (error.status === 400) {
      const err = error.error;
      if (err.ModelState) {
        for (const item in err.ModelState) {
          const arr = item.split('.');
          this.toastr.error(err.ModelState[item][err.ModelState[item].length - 1], arr[1] ? arr[1] : 'Error !!!', {
            positionClass: 'toast-top-right'
          });
         /* err.ModelState[item].forEach((msg) => {
            this.toastr.error(msg, arr[1] ? arr[1] : 'Error !!!', {
              positionClass: 'toast-top-right'
            });
          })*/

        }
      }
    }
    // return an ErrorObservable with a user-facing error message
    return of(
      'Something bad happened; please try again later.');
  }

  public userActiveHeaders() {
    let headers;
    headers = new HttpHeaders({
      'pid': environment.partnerId,
      'uLang': this.settings.activeLangId,
      'languageId': this.settings.activeLangId,
      session: localStorage.getItem('session') || '',
      uid: localStorage.getItem('uid') || ''
    });
    return {headers};
  }

  public activeHeaders() {
    let headers;
    headers = {
      'pid': environment.partnerId,
      'uLang': this.settings.activeLangId,
      'languageId': this.settings.activeLangId,
      session: localStorage.getItem('session') || '',
      uid: localStorage.getItem('uid') || ''
    };
    return headers;
  }

  monitor(): Observable<boolean> {
    return this.connectionMonitor;
  }
}


