<div class="modal-header">

  <h4 class="modal-title"> {{'Transfer Money to agent' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="popup('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="needs-validation" [formGroup]="transferForm" (ngSubmit)="onReactiveFormSubmit()">
    <div class="form-row">
      <div class="col-sm-12">
        <ul class="list-unstyled">
          <li>{{'My Balance' | translate}}: {{Settings.userData.wallet.balance | currency : Settings.userData.wallet.currency}}</li>
          <!--<li>{{'My Credit' | translate}}: {{Settings.userData.wallet.credit}} {{Settings.userData.wallet.currency}}</li>-->
        </ul>
      </div>
      <div class="col-sm-12 form-group">
        <label for="Operation">{{'Operation' | translate}}</label>
        <select class="form-control" formControlName="OperationType" id="Operation">
          <option [value]="operationsEnum.Deposit" *ngIf="Core.checkPermissions(['deposittoagent'])">{{operationsEnum[operationsEnum.Deposit]}}</option>
          <option [value]="operationsEnum.Withdraw" *ngIf="Core.checkPermissions(['agentwithdraw'])">{{operationsEnum[operationsEnum.Withdraw]}}</option>
        </select>
      </div>

      <div class="col-sm-12 form-group">
        <label for="agent">Agents</label>
        <ng-select [items]="agentsList"
                   bindLabel="name"
                   id="agent"
                   bindValue="id"
                   formControlName="agent"
                   (change)="agentChanged($event)"
                   [ngClass]="{ 'is-invalid': submitted && f.agent.invalid }" required>
        </ng-select>
        <div *ngIf="submitted && (f.agent.invalid || f.agent.errors?.required)" class="invalid-tooltip">Required</div>
      </div>
      <div class="col-sm-12 form-group">
        <label for="Amount">{{'Amount' | translate}}</label>
        <input type="number" formControlName="Amount" class="form-control" id="Amount" placeholder="Amount" [ngClass]="{ 'is-invalid': submitted && f.Amount.invalid }">

        <div *ngIf="submitted && (f.Amount.invalid || f.Amount.errors?.required)" class="invalid-tooltip">Required</div>
      </div>

      <div class="col-sm-12 form-group">
        <label for="MiddleName">{{'Comment For Transfer' | translate}}</label>
        <textarea type="text" formControlName="Comment" class="form-control" id="MiddleName"
                  placeholder="Note For Transfer" [ngClass]="{ 'is-invalid': submitted && f.Comment.invalid }"> </textarea>
        <div *ngIf="submitted && (f.Comment.invalid || f.Comment.errors?.required)" class="invalid-tooltip">Required</div>
      </div>
<!--
      <div class="col-sm-12 form-group">

        <ul class="list-unstyled mb-0">
          <li class="d-inline-block mr-2">
            <div class="radio radio-primary">
              <input type="radio" name="BalanceType" id="color-radio-1" formControlName="BalanceType"
                     value="{{agentBalanceTypeEnum.Real}}">
              <label for="color-radio-1">{{agentBalanceTypeEnum[agentBalanceTypeEnum.Real]}}</label>
            </div>
          </li>
          <li class="d-inline-block mr-2">
            <div class="radio radio-secondary">
              <input type="radio" name="BalanceType" id="Credit" formControlName="BalanceType"
                     value="{{agentBalanceTypeEnum.Credit}}">
              <label for="Credit">{{agentBalanceTypeEnum[agentBalanceTypeEnum.Credit]}}</label>
            </div>
          </li>
        </ul>

      </div>-->

      <div class="col-sm-12">
        <ul class="list-unstyled">
          <li>{{'Agent Balance' | translate}}: {{selectedAgent.balance | currency: selectedAgent.currency}}</li>
         <!-- <li>{{'Agent Credit' | translate}}: {{selectedAgent.credit}} {{selectedAgent.currency}}</li>-->
        </ul>
      </div>

    </div>

    <br>
    <br>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary is-invalid" (click)="popup('Cross click')">{{'Close' | translate}}</button>
      <button class="btn btn-primary" type="submit">{{'Submit' | translate}}</button>
    </div>
  </form>
</div>
