import {Injectable} from '@angular/core';


import {GridDataResult} from '@progress/kendo-angular-grid';
import {Observable, BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RequestsService} from '../requests.service';
import {FilterOperationTypes} from '../../enums/enums.enum';
import {GridFieldsTypes} from '../../enums/grid-fields.enum';
import * as moment from 'moment';


export abstract class NorthwindService extends BehaviorSubject<GridDataResult> {
  public loading = false;

  public BASE_URL = '';
  public filterWithOperationType = true;
  public httpType = 'Post';

  constructor(private http: RequestsService, protected queryType: string) {
    // @ts-ignore
    super(null);
  }

  public query(state: any, body?: any): void {
    this.fetch(this.BASE_URL, state, body).subscribe((x) => super.next(x));
  }

  public queryNoOperationType(state: any, body?: any): void {
    this.fetch(this.BASE_URL, state, body).subscribe((x) => super.next(x));
  }

  createFilterObj(values: any) {
    const filterObj: any = {};
    for (const key in values) {
      if (key === 'FromDate') {
        filterObj['Date'] = {
          Value: values['FromDate'],
          ToValue: values['ToDate'], //moment(values['ToDate'], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
          Operation: GridFieldsTypes[key],
        }
      }
      if (key === 'LastUpdateFrom' || key === 'LastUpdateTo') {
        if (values['LastUpdateFrom'] && values['LastUpdateTo']) {
          filterObj['LastUpdate'] = {
            Value: values['LastUpdateFrom'] ? values['LastUpdateFrom'] : null,
            ToValue: values['LastUpdateTo'] ? moment(values['LastUpdateTo'], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') : null,
            Operation: FilterOperationTypes.Between,
          }
        }
      }
      if (key === 'RequestDateFrom' || key === 'RequestDateTo') {

        if (values['RequestDateFrom'] && values['RequestDateTo']) {
          filterObj['RequestDate'] = {
            Value: values['RequestDateFrom'] ? values['RequestDateFrom'] : null,
            ToValue: values['RequestDateTo'] ? moment(values['RequestDateTo'], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') : null,
            Operation: FilterOperationTypes.Between,
          }
        }
      }
      if (key === 'AmountFrom' || key === 'AmountTo') {
        filterObj['Amount'] = {
          Value: values['AmountFrom'],
          ToValue: values['AmountTo'],
          Operation: FilterOperationTypes.Between,
        }
      }
      if ((values[key] || values[key] === 0) && (key !== 'FromDate' && key !== 'ToDate')) {
        filterObj[key] = {
          Value: values[key],
          Operation: GridFieldsTypes[key],
        };
      }
    }

    return filterObj;

  }

  protected fetch(BASE_URL: string, state: any, body?: any): Observable<GridDataResult> {

    let queryStr = `pageNumber=${state.skip / state.take}&pageSize=${state.take}`;
    this.loading = true;
    if (state && state.sort && state.sort.length) {
      queryStr += '&sort=' + (state.sort[0].dir === 'asc' ? '' : '-') + state.sort[0].field;
    }

    let url = ''
    if (this.BASE_URL.indexOf('?') > -1) {
      url = `${this.BASE_URL}&${queryStr}`
    } else {
      url = `${this.BASE_URL}?${queryStr}`
    }
    if (this.queryType === 'Get' || this.httpType === 'Get') {

      return this.http.httpGet(url).pipe(
        map(
          (response: any) =>
            <GridDataResult>{
              data: response['data'],
              total: parseInt(response['totalCounts'], 10),
            }
        ),
        tap(() => (this.loading = false))
      );
    }
    let filterBody: any;
    if (this.filterWithOperationType) {
      filterBody = this.createFilterObj(body)
    } else {
      filterBody = body;
    }

    if (state && state.filter && state.filter.filters && state.filter.logic !== 'and') {
      state.filter.filters.forEach((item: any) => {
        filterBody[item.field] = {
          Value: item.value,
          Operation: FilterOperationTypes[item.operator] // this.FilterOperationTypes[item.operator]
        };
      });
    }

    if (state && state.filter && state.filter.filters && state.filter.logic === 'and') {
      state.filter.filters.forEach((filters: any, key: any) => {
        filters.filters.forEach((item: any) => {
          filterBody[item.field] = {
            Value: item.value,
            Operation: FilterOperationTypes[item.operator] // this.FilterOperationTypes[item.operator]
          };
        });
      })
    }
    return this.http.httpPost(url, {filter: filterBody}).pipe(
      map(
        (response: any) =>
          <GridDataResult>{
            data: response['data'],
            total: parseInt(response['totalCounts'], 10),
          }
      ),
      tap(() => (this.loading = false))
    );


  }
}

@Injectable({
  providedIn: 'root'
})
export class GridGetService extends NorthwindService {
  constructor(http: RequestsService) {
    super(http, 'Get');
  }

  queryAll(BASE_URL: any, st?: any): Observable<GridDataResult> {
    const state = Object.assign({}, st);
    delete state.skip;
    delete state.take;

    this.BASE_URL = BASE_URL;
    return this.fetch(BASE_URL, state);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GridPostService extends NorthwindService {
  constructor(http: RequestsService) {
    super(http, 'Post');
  }

  queryAll(BASE_URL: any, st?: any): Observable<GridDataResult> {
    const state = Object.assign({}, st);
    delete state.skip;
    delete state.take;
    this.BASE_URL = BASE_URL;

    return this.fetch(BASE_URL, state);
  }
}

@Injectable()
export class PartnerPostService extends NorthwindService {
  constructor(http: RequestsService) {
    super(http, 'Post');
  }

  queryAll(BASE_URL: any, st?: any): Observable<GridDataResult> {
    const state = Object.assign({}, st);
    delete state.skip;
    delete state.take;
    this.BASE_URL = BASE_URL;

    return this.fetch(BASE_URL, state);
  }
}
