import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-new-msg',
  templateUrl: './new-msg.component.html',
  styleUrls: ['./new-msg.component.css']
})

export class NewMsgComponent implements OnInit {
  agentId: any;
  constructor() {

  }

  ngOnInit() {
  }
}
