import {RouteInfo} from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Network', icon: 'icon-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/network/users',
        title: 'Users',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/network/user-group',
        title: 'User Group',
        permissions: 'isAdmin',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/network/tree',
        title: 'User Tree',
        permissions: 'isAdmin',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/network/pending-users',
        title: 'Pending Users',
        permissions: 'isAdmin',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/network/deleted-users',
        title: 'Deleted Users',
        permissions: 'isAdmin',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },

  {
    path: '', title: 'Players', icon: 'icon-game-controller', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/players/players',
        title: 'Players',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/players/deleted-players',
        title: 'Blocked Players',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'Commission Plan',
    icon: 'ft-zap',
    permissions: 'isAdmin',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/commission-plan/revenue',
        title: 'Revenue',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/commission-plan/cpa',
        title: 'CPA',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/commission-plan/hybrid',
        title: 'Hybrid',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/commission-plan/referral',
        title: 'Referral',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },
  {
    path: '', title: 'Payouts', icon: 'ft-credit-card', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/withdrawals/sub-users-requests',
        title: 'User Requests',
        permissions: 'isAdmin',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/withdrawals/my-requests',
        title: 'My Requests',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/reports',
    title: 'Reports',
    icon: 'ft-bar-chart-2',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/reports/transactions/users',
        title: 'Transactions',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/reports/players',
        title: 'By Player',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/reports/by-product',
        title: 'By Product',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/reports/by-agent',
        title: 'By Sub Users',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      // {
      // path: '/reports/by-agent-daily',
      //  title: 'Agent Daily', icon: 'ft-arrow-right submenu-icon',
      //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },

    ]
  },

  /* { path: '/inbox', title: 'Email', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },*/
  {
    path: '',
    title: 'Settings',
    icon: 'ft-settings',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    permissions: 'isAdmin',
    submenu: [
      {
        path: '/settings/products',
        title: 'Products',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/payments',
        title: 'Payments',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/languages',
        title: 'Languages',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/currency',
        title: 'Currency',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/permissions',
        title: 'permissions',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/content-management',
        title: 'CMS',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

/*
            { path: '/settings/reg-from', title: 'Registration Form', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
*/

      {
        path: '/settings/email-templates',
        title: 'Email Templates',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/contribution-costs',
        title: 'Contribution Costs',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/settings/domains',
        title: 'Domains',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },
  {
    path: '', title: 'Tools', icon: 'icon-wrench', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '',
        title: 'Media',
        icon: 'ft-arrow-right submenu-icon',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/market-tools/media/images',
            title: 'Images',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/market-tools/media/video',
            title: 'Videos',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
        ]
      },
      {
        path: '',
        title: 'Banners',
        icon: 'ft-arrow-right submenu-icon',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/market-tools/banners/images',
            title: 'Images',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/market-tools/banners/video',
            title: 'Videos',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
        ]
      },
      {
        path: '/market-tools/new-link',
        title: 'Links',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

    ]
  },
  {
    path: '', title: 'Marketing', icon: 'ft-target', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/marketing/campaigns',
        title: 'Campaigns',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
/*      {
        path: '/marketing/bonuses',
        title: 'Bonuses',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },*/
      {
        path: '/marketing/newsletter',
        title: 'Newsletter',
        icon: 'ft-arrow-right submenu-icon',
        permissions: 'isAdmin',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },
];

