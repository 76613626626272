<div class="card commission-card" *ngIf="dataItem">
  <div class="card-header d-flex justify-content-between align-items-center">
    <h4 class="card-title m-0">{{dataItem.name}}
      <span class="badge badge-success mb-1 mr-2 k-display-block" style="font-size: 8px;padding: 4px;width: 46px;" *ngIf="dataItem.default"> Default     </span>
    </h4>
    <span *ngIf="!isEditable && !hideTopIcons">
      <a href="javascript:;" class="plan-type m-2 text-secondary">{{PlanTypes[dataItem.type]}}</a>

      <a [routerLink]="['/commission-plan/edit-revenue', dataItem.id]"  class="m-1" title="{{'Edit' | translate}}" *ngIf="dataItem.type == PlanTypes.Deposit">
        <i class="ft-edit text-primary cursor-pointer"></i>
      </a>
      <a [routerLink]="['/commission-plan/edit-cpa', dataItem.id]"  class="m-1" title="{{'Edit' | translate}}" *ngIf="dataItem.type == PlanTypes.CPA">
        <i class="ft-edit text-primary cursor-pointer"></i>
      </a>
      <a [routerLink]="['/commission-plan/edit-hybrid', dataItem.id]"  class="m-1" title="{{'Edit' | translate}}" *ngIf="dataItem.type == PlanTypes.Hybrid">
        <i class="ft-edit text-primary cursor-pointer"></i>
      </a>
      <a [routerLink]="['/commission-plan/edit-referral', dataItem.id]"  class="m-1" title="{{'Edit' | translate}}" *ngIf="dataItem.type == PlanTypes.Referral">
        <i class="ft-edit text-primary cursor-pointer"></i>
      </a>
      <a href="javascript:;" class="plan-type m-1" (click)="deletePlan(dataItem.id)" *ngIf="dataItem.deletable">
        <i class="ft-trash-2 text-danger cursor-pointer"></i>
      </a>
      <a href="javascript:;" title="{{'Assigned' | translate}} {{dataItem.agents}}">
        <i class="ft-users text-info cursor-pointer"></i>
      </a>

      <a href="javascript:;" class="plan-type m-1" title="Set Default" (click)="setDefault(dataItem)"  *ngIf="!dataItem.default">
        <i class="ft-check-circle text-success cursor-pointer"></i>
      </a>
    </span>
    <a href="javascript:;"  *ngIf="showHide && hiddenContent" (click)="hiddenContent = !hiddenContent">
      <i class="ft-chevron-down text-primary cursor-pointer"></i>
    </a>
    <a href="javascript:;"  *ngIf="showHide && !hiddenContent" (click)="hiddenContent = !hiddenContent">
      <i class="ft-chevron-up text-primary cursor-pointer"></i>
    </a>
  </div>
 <!-- <div class="card-header d-flex justify-content-between align-items-center"  *ngIf="!isEditable && !hideTopIcons">
    <span class="text-bold-500"> Is default</span>
    <div class="float-right">
      <div class="custom-switch">
        <input class="custom-control-input" id="left-s-switch-{{dataItem.id}}" type="checkbox"
               [checked]="dataItem.default" [(ngModel)]="dataItem.default" (change)="changeIsDefoult(dataItem)" [attr.disabled]="dataItem.default ? '': null">
        <label class="custom-control-label" for="left-s-switch-{{dataItem.id}}"></label>
      </div>
    </div>
  </div>-->
  <div class="card-content" [hidden]="hiddenContent">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm m-0">
          <thead>
            <tr>
              <th>
                {{'Level' | translate}}
              </th>
              <th>%</th>
              <th *ngIf="isEditable">{{'Action' | translate}}</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of dataItem.ranges; let ind = index" class="comittion_row">
            <td>
              <input type="text" class="comittion_row_inp"
                     [value]="item.min"
                     [(ngModel)]="item.min"
                     [attr.disabled]="''"
                     (keyup)="fromChanged(ind, $event.target)">
            </td>
            <td>
              <input type="text" class="comittion_row_inp"
                     [value]="item.percent"
                     [(ngModel)]="item.percent"
                     [attr.disabled]="!isEditable ? '' : null"
                      (keyup)="checkChanges()">
            </td>
            <td class="action-column" *ngIf="isEditable">
              <a href="javascript:;" class="m-1" *ngIf="ind < (dataItem.ranges.length -1)" (click)="deleteRow(ind)">
                <i class="ft-trash-2 text-danger cursor-pointer"></i>
              </a>
              <a href="javascript:;" class="m-1"  *ngIf="ind == (dataItem.ranges.length -1)" (click)="addNewRow()">
                <i class="ft-plus-square text-primary cursor-pointer"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

