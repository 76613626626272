import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'network',
    loadChildren: () => import('../../network/network.module').then(m => m.NetworkModule)
  },
  {
    path: 'players',
    loadChildren: () => import('../../players/players.module').then(m => m.PlayersModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'player',
    loadChildren: () => import('../../player-account/player-account.module').then(m => m.PlayerAccountModule)
  },
  {
    path: 'withdrawals',
    loadChildren: () => import('../../withdrawals/withdrawals.module').then(m => m.WithdrawalsModule)
  },
  {
    path: 'market-tools',
    loadChildren: () => import('../../marketTools/marketTools.module').then(m => m.MarketToolsModule)
  },
  {
    path: 'marketing',
    loadChildren: () => import('../../marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'commission-plan',
    loadChildren: () => import('../../commission-plan/commission-plan.module').then(m => m.CommissionPlanModule)
  }
];
