import {FilterOperationTypes} from './enums.enum';

export const GridFieldsTypes: any = {
  Id: FilterOperationTypes.eq,
  roleAbv: FilterOperationTypes.eq,
  State: FilterOperationTypes.eq,
  WebState: FilterOperationTypes.eq,
  parentId: FilterOperationTypes.eq,
  players: FilterOperationTypes.eq,
  pcm: FilterOperationTypes.eq,
  cm: FilterOperationTypes.eq,
  Status: FilterOperationTypes.eq,
  AgentRoleId: FilterOperationTypes.eq,
  CountryId: FilterOperationTypes.eq,
  OperationType: FilterOperationTypes.eq,
  BalanceType: FilterOperationTypes.eq,


  username: FilterOperationTypes.contains,
  Username: FilterOperationTypes.contains,
  FirstName: FilterOperationTypes.contains,
  LastName: FilterOperationTypes.contains,
  bTag: FilterOperationTypes.contains,
  Name: FilterOperationTypes.contains,
  currency: FilterOperationTypes.contains,
  AgentName: FilterOperationTypes.contains,
  ReceiverName: FilterOperationTypes.contains,
  TimeZone: FilterOperationTypes.contains,



  FromDate: FilterOperationTypes.Between,
  ToDate: FilterOperationTypes.Between,
}
