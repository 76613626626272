import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../services/config.service';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {SettingsService} from '../../services/settings.service';
import {CoreService} from '../../services/core.service';
import {RequestsService} from '../../services/requests.service';
import {AuthService} from '../../services/auth/auth.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  public rightMenuOpened = false;
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription | undefined;
  public filePath = environment.skinFilePath;

  @ViewChild('search') searchElement: ElementRef | undefined;
  @ViewChildren('searchResults') searchResults: QueryList<any> | undefined;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems: Array<any> = [];
  control = new FormControl();

  public config: any = {};

  constructor(public translate: TranslateService,
              private layoutService: LayoutService,
              private auth: AuthService,
              public Settings: SettingsService,
              public http: RequestsService,
              public core: CoreService,
              private router: Router,
              private configService: ConfigService, private cdr: ChangeDetectorRef) {
    let browserLang: any = 'en';
    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang');
      this.ChangeLanguage(browserLang);
    }

    translate.use(browserLang);
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    // this.listItems = LISTITEMS;
    this.onEscEvent()
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {

    if (this.searchResults && this.searchResults.length > 1) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');

    }

    if (event.target.value === '' || event.target.value.length < 2) {
      // this.control.setValue('');
      this.listItems = []
      this.seachTextEmpty.emit(true);
    } else {

      this.control.setValue(event.target.value);
      this.http.httpPost(this.Settings.apiUrls.agents.globalFind, {Find: event.target.value})
        .subscribe((res: any) => {
          if (res.data) {
            this.listItems = res.data;
          }
        });
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.listItems = [];
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value: any) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/us.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Spanish';
      this.selectedLanguageFlag = './assets/img/flags/es.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Portuguese';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    } else if (language === 'de') {
      this.selectedLanguageText = 'German';
      this.selectedLanguageFlag = './assets/img/flags/de.png';
    } else if (language === 'tr') {
      this.selectedLanguageText = 'Turkish';
      this.selectedLanguageFlag = './assets/img/flags/tr.png';
    }
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display: any) {
    this.control.setValue('');
    this.listItems = [];
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement?.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }


  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logOut() {
    return this.auth.logout()
  }
  openCreateModal(type: string){
    this.Settings.openCreateUserModal.emit({type: type});
  }
  openTransferModal(type: string){
    this.core.openTransferEvent.emit({type: type});
  }
  openPartnerUrlsModal(){
    this.Settings.openPartnerUrlsModal.emit(true);
  }
}
