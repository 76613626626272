import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';

import {NgbAccordionModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';


import {AutocompleteModule} from './components/autocomplete/autocomplete.module';


// COMPONENTS
import {FooterComponent} from './components/footer/footer.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {VerticalMenuComponent} from './components/vertical-menu/vertical-menu.component';
import {NotificationSidebarComponent} from './components/notification-sidebar/notification-sidebar.component';

// DIRECTIVES
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {SidebarLinkDirective} from './directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {TopMenuDirective} from './directives/topmenu.directive';
import {TopMenuLinkDirective} from './directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './directives/topmenu-anchor-toggle.directive';
import {CreateMainComponent} from './components/popups/create/create-main/create-main.component';
import {CreatePlayerComponent} from './components/popups/create/create-player/create-player.component';
import {CreateAgentComponent} from './components/popups/create/create-agent/create-agent.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ListTransferComponent} from './components/list-transfer/list-transfer.component';
import {CommissionCardComponent} from './components/commission-card/commission-card.component';
import {ToastrModule} from 'ngx-toastr';
import {TransferComponent} from './components/popups/transfer/transfer.component';
import {TransferAgentComponent} from './components/popups/transfer/transfer-agent/transfer-agent.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TransferPlayerComponent} from './components/popups/transfer/transfer-player/transfer-player.component';
import {SetPermissionComponent} from './components/popups/set-permission/set-permission.component';
import {NewMsgComponent} from './components/popups/new-msg/new-msg.component';
import {SendMessageComponent} from './components/email/send-message/send-message.component';
import {GridDataBindingDirective} from './components/grid-data-binding/grid-data-binding.directive';
import {GridModule} from '@progress/kendo-angular-grid';

import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import {DragulaModule} from 'ng2-dragula';
import {RegformStepsComponent} from './components/regform-steps/regform-steps.component';
import {CommissionCardReferralComponent} from './components/commission-card-referral/commission-card-referral.component';
import {AccountAcctionsComponent} from './components/account-acctions/account-acctions.component';
import {RegisterStepComponent} from './components/popups/register-step/register-step.component';
import {PartnerLinksComponent} from './components/popups/partner-links/partner-links.component';
import {PipeModule} from "./pipes/pipe.module";
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://stg-data.faunabet.com',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    CreateMainComponent,
    CreatePlayerComponent,
    CreateAgentComponent,
    NavbarComponent,
    VerticalMenuComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    NgSelectModule,
    TranslateModule,
    ListTransferComponent,
    CommissionCardComponent,
    CommissionCardReferralComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    TransferComponent,
    TransferAgentComponent,
    TransferPlayerComponent,
    SetPermissionComponent,
    NgxDatatableModule,
    PipeModule,
    SendMessageComponent,
    GridDataBindingDirective,
    RegformStepsComponent,
    AccountAcctionsComponent,
    RegisterStepComponent,
    PartnerLinksComponent,
    DropzoneModule,
    NgbAccordionModule,
    GridModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    NgSelectModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AutocompleteModule,
    PipeModule,
    NgxDatatableModule,
    ToastrModule.forRoot(),
    GridModule,
  //  DragulaModule.forRoot(),
    DropzoneModule,
    NgbAccordionModule,

  ],
  declarations: [
    FooterComponent,
    CreateMainComponent,
    CreatePlayerComponent,
    CreateAgentComponent,
    NavbarComponent,
    VerticalMenuComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    ListTransferComponent,
    CommissionCardComponent,
    CommissionCardReferralComponent,
    TransferComponent,
    TransferAgentComponent,
    TransferPlayerComponent,
    SetPermissionComponent,
    NewMsgComponent,
    SendMessageComponent,
    GridDataBindingDirective,
    RegformStepsComponent,
    AccountAcctionsComponent,
    RegisterStepComponent,
    PartnerLinksComponent,

  ],
  providers: [ {
    provide: DROPZONE_CONFIG,
    useValue: DEFAULT_DROPZONE_CONFIG
  }]
})
export class SharedModule {
  constructor() {
  }
}
