import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../../services/settings.service';
import {RequestsService} from '../../../../services/requests.service';
import {CoreService} from '../../../../services/core.service';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-create-player',
  templateUrl: './create-player.component.html'
})
export class CreatePlayerComponent implements OnInit, OnDestroy {
  @Input() popup: any;
  public submitted = false;
  cities: Array<any> = [
    {Id: 1, Name: 'Vilnius'},
    {Id: 2, Name: 'Kaunas'},
    {Id: 3, Name: 'Pavilnys'},
    {Id: 4, Name: 'Pabradė'},
    {Id: 5, Name: 'Klaipėda'}
  ];
  public PlayerForm: any = new FormGroup({
    FirstName: new FormControl(''),
    MiddleName: new FormControl(''),
    //   MiddleName: new FormControl(''),
    LastName: new FormControl(''),
    Username: new FormControl('', [Validators.required]),
    Phone: new FormControl(null),
    CurrencyId: new FormControl('', [Validators.required]),
    Email: new FormControl('', [Validators.required, Validators.email]),
    Password: new FormControl('', [Validators.required]),
    CountryId: new FormControl('', [Validators.required]),
    City: new FormControl('', [Validators.required]), // new
    BirthDate: new FormControl('', [Validators.required]), // new
    AgentId: new FormControl('', [Validators.required]),
  });

  public countries: Array<any> = [];
  public currenciesList: Array<any> = [];
  public agents: Array<any> = [];

  public partnerId = Number(environment.partnerId);

  constructor(
    public Settings: SettingsService,
    public Core: CoreService,
    public Http: RequestsService
  ) {

    if (this.partnerId === 16) {
      this.PlayerForm.patchValue({CurrencyId: 'LBP'})
    }

    this.Http.httpGet(`${this.Settings.apiUrls.options.countries}?languageId=en`).subscribe((res: any) => {
      if (res.data) {
        this.countries = res.data;
      }
    });
    this.Http.httpGet(`${this.Settings.apiUrls.options.partnerCurrencies}`).subscribe((res: any) => {
      if (res.data) {
        this.currenciesList = res.data;
      }
    });
    this.Http.httpGet(this.Settings.apiUrls.options.agents + '?includeParent=true').subscribe((res: any) => {
      if (res.data) {
        this.agents = res.data;
      }
    });
  }

  ngOnInit() {
  }

  get f() {
    return this.PlayerForm.controls;
  }

  onReactiveFormSubmit() {
    this.submitted = true;
    if (this.PlayerForm.invalid) {
      return;
    }
    if (!this.PlayerForm.value.Phone) {
      this.PlayerForm.value.Phone = null
    }

    this.Http.httpPost(`${this.Settings.apiUrls.player.addPlayer}`, this.PlayerForm.value, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.popup('Cross click');
          this.Core.showInfoMsg('Player Created');
        }

      })
  }

  ngOnDestroy() {
  }
}
