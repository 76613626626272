<div class="dropdown btn-group mr-1 mb-1" ngbDropdown>
  <button type="button" class="btn btn-outline-info dropdown-toggle" ngbDropdownToggle>
    Actions
    <span class="caret"></span>
  </button>
  <div ngbDropdownMenu class="dropdown-menu" role="menu">
    <!--<a class="dropdown-item" (click)="openActionPopup(actionsModalRef, 'cpaLiveTime')">Cpa Live Time</a>
    <a class="dropdown-item" (click)="openActionPopup(actionsModalRef, 'revenueLiveTime')">Revenue Live Time</a>-->
    <a class="dropdown-item" (click)="openActionPopup(actionsModalRef, 'flatFee')">Flat Fee</a>
    <a class="dropdown-item" (click)="openActionPopup(actionsModalRef, 'partnerUrl')">Partner Url</a>
  </div>
</div>

<ng-template #actionsModalRef let-c="close" let-d="dismiss">
  <div>
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="activeType == 'cpaLiveTime'">Cpa Live Time</h4>
      <h4 class="modal-title" *ngIf="activeType == 'revenueLiveTime'">Revenue Live Time</h4>
      <h4 class="modal-title" *ngIf="activeType == 'flatFee'">Flat Fee</h4>
      <h4 class="modal-title" *ngIf="activeType == 'partnerUrl'">Partner Url</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
          <div class="col-sm-12">


            <div class="form-group" *ngIf="activeType == 'cpaLiveTime'">
              <label>Cpa Life Time *</label>
              <input type="number" class="form-control" [(ngModel)]="cpaLiveTime" style="width: 100%">
            </div>
            <div class="form-group" *ngIf="activeType == 'revenueLiveTime'">
              <label>Revenue Life Time *</label>
              <input type="number" class="form-control" [(ngModel)]="revenueLiveTime" style="width: 100%">
            </div>
            <div class="form-group" *ngIf="activeType == 'flatFee'">
              <label>Flat Fee *</label>
              <input type="number" class="form-control" [(ngModel)]="flatFee" style="width: 100%">
            </div>
            <div class="form-group" *ngIf="activeType == 'partnerUrl'">
              <label for="Status">{{'Partner Url' | translate}}</label>
              <ng-select [items]="domainsList"
                         id="Status"
                         bindLabel="url"
                         bindValue="id"
                         [(ngModel)]="partnerUrl"
                         placeholder="Payment"
                         style="width: 100%"
              >
              </ng-select>
            </div>


          </div>
        </div>

      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="c('Close click')">{{'Close' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="save()">
        {{'Save' | translate}}
      </button>
    </div>


  </div>

</ng-template>


