import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {RequestsService} from './requests.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  public agentInfo: any;

  public agentId = 0;

  constructor(
    public Settings: SettingsService,
    public Http: RequestsService,
  ) {

  }

  getAgentById(agentId: any) {
    this.agentId = agentId;
    this.Http.httpGet(this.Settings.apiUrls.agents.agent + agentId, true)
      .subscribe((res: any) => {
        if (res.data) {
          this.agentInfo = res.data;
        }
      })
  }

}
