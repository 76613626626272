import {Injectable} from '@angular/core';
import {SettingsService} from '../settings.service';

@Injectable({
  providedIn: 'root'
})

export class ChatInvokeService {
  public selectedMsgId = 0;
  public isMessageSelected = true;
  public getMsgFilter = {
    CallerId: localStorage.getItem('uid'),
    Text: '',
    OutBox: false,
    Type: null,
    Skip: 0,
    Take: 80,
    AgentId: null,
    Unreads: false,
  };

  constructor(
    private Settings: SettingsService
  ) {
    this.Settings.signalREvent.subscribe((ev) => {
      if (ev) {
        // this.getMessages()
      }
    });

  }


  getMessages(filter?: any) {
    this.Settings.sigCon.hubProxy.invoke('getMessages', this.getMsgFilter);
  }

  getMessageById(msgId: any) {
    this.Settings.sigCon.hubProxy.invoke('messageById', {
      CallerId: localStorage.getItem('uid'),
      MessageId: msgId
    });
  }

  removeMsgById(msgId: any) {
    this.Settings.sigCon.hubProxy.invoke('removeMessage', {
      CallerId: localStorage.getItem('uid'),
      MessageId: msgId
    });
  }

  sendMsg(obj: any) {
    obj.senderId= localStorage.getItem('uid');
    this.Settings.sigCon.hubProxy.invoke('sendMessage', obj);
  }
}
